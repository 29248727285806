import "keen-slider/keen-slider.min.css";

import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { IoMdArrowDropdown } from "react-icons/io";
import Link from 'next/link'
import Router from "next/router";
import StaticUtils from "../../services/static-utils.service";
import { config } from "../../services/config.service";
import { prismicPageDataService } from "../../services/prismicPage.service";

const CategoryList = (props: any) => {

  const { categories } = props;

  const [featuredCollection, setFaturedCollection] = React.useState<any>();
  const [pageCategories, setCategories] = React.useState<any>();
  const [mainCategories, setMainCategories] = React.useState<any>();

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  let refinedFeaturesData: any = [];
  const envCustomType: any = config.prismicFeaturedCollection?.toString();
  const envFeaturedUID: any = config.prismicFeaturedBrandsUid?.toString();
  // async function getAllFeaturedCols() {
  //   if (featuredCollection && featuredCollection.length) {
  //     return;
  //   }

  //   let featuredData: any = await prismicPageDataService.getReqPrismicTypeById(`${envCustomType}`, `${envFeaturedUID}`);

  //   featuredData.props.pageRes.map((val: any, key: any) => {

  //     refinedFeaturesData.push({
  //       "title": `${val?.primary?.title[0]?.text}`,
  //       "logo": `${val?.primary?.featured_logo?.url}`,
  //       "logoHeight": `${val?.primary?.featured_logo?.dimensions?.width}`,
  //       "logoWidth": `${val?.primary?.featured_logo?.dimensions?.height}`,
  //       "url": `${val?.primary?.url?.url}`,
  //       "tooltip": `${val?.primary?.tooltip[0]?.text}`
  //     });

  //   });

  //   setFaturedCollection(refinedFeaturesData);

  //   return refinedFeaturesData;
  // }

  React.useMemo(() => {

    if (!props.categories || !props.categories.length) {
      return;
    }

    if ((!pageCategories || !pageCategories.length) && props.categories) {
      setCategories(props.categories.filter((c: any) => {
        return !c.customFields.featuredCollection
      }))
      setMainCategories(props.categories.filter((c: any) => {
        return !c.customFields.featuredCollection && c.featuredAsset
      }))
    }

    // if (!featuredCollection) {
    //   getAllFeaturedCols();
    // }

    //if ((!featuredCollection || !featuredCollection.length) && props.categories) {


    // setFaturedCollection(props.categories.filter((c: any) => {
    //   return c.customFields.showInBanner && c.customFields.featuredCollection
    // }));
    //}
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Center
      height="40px"
    >
      {isDesktop && (
        <>
          <Stack
            direction={"row"}
            justifyContent="start"
            margin-left="0px"
            columnGap='30px'
          >

            <Box>
              <Popover trigger={"hover"} placement={"bottom-start"}>
                <PopoverTrigger>
                  <Link href='/search?search=;category='>

                    <Flex
                      direction="column"
                      py="3"
                      alignItems="center"
                      justifyContent="center"
                      mt={{ lg: "20px", xl: "5px" }}
                      transition='all .3s ease 0s'
                      _hover={{ opacity: "0.8" }}
                      cursor={"pointer"}
                    >
                      <HStack spacing={0}>
                        <Text
                          fontFamily={StaticUtils.titleFontName}
                          fontSize="16px"
                          fontWeight={500}
                          whiteSpace='nowrap'
                          color="#1F2D47"
                          _hover={{
                            textDecoration: "none",
                            color: linkHoverColor,
                          }}
                        >
                          All Products
                        </Text>
                      </HStack>
                    </Flex>

                  </Link>
                </PopoverTrigger>
              </Popover>
            </Box>


            {/* main menu navigation items for first 5 items */}
            {mainCategories && mainCategories.map((navItem: any, idx: number) => {
              // if (idx < 4) {
              return (
                <Box key={navItem.id} style={{ 'margin': '0' }}>
                  <Popover trigger={"hover"} placement={"bottom-start"}>

                    <Link href={"/category/" + navItem.slug.toLowerCase()}
                    // onClick={() => handleNav(navItem.slug)}
                    >
                      <PopoverTrigger>
                        <Flex
                          direction="column"
                          py="3"
                          alignItems="center"
                          justifyContent="center"
                          mt={{ lg: "20px", xl: "5px" }}
                          transition='all .3s ease 0s'
                          _hover={{ opacity: "0.8" }}
                          cursor={"pointer"}
                        >
                          <HStack spacing={0}>
                            <Text
                              fontFamily={StaticUtils.titleFontName}
                              fontSize="16px"
                              fontWeight={500}
                              whiteSpace='nowrap'
                              color="#1F2D47"
                              _hover={{
                                textDecoration: "none",
                                color: linkHoverColor,
                              }}
                            >
                              {navItem.name}
                            </Text>
                            {navItem.children.length > 0 && (
                              <Box as={IoMdArrowDropdown} fontSize="md" />
                            )}
                          </HStack>
                        </Flex>

                      </PopoverTrigger>

                    </Link>
                    {navItem.children.length > 0 && (
                      <PopoverContent
                        border={0}
                        boxShadow='0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)'
                        bg='#FBF8F5'
                        p={10}
                        rounded={0}
                        w='560px'
                      >
                        <PopoverBody >
                          <Stack maxHeight='180px' flexWrap='wrap'>
                            {navItem.children.map(
                              (child: any, key: string) => (
                                <Link
                                  key={key}
                                  href={"/category/" + child.slug.toLowerCase()}
                                  // onClick={() => handleNav(child.slug)}
                                  // eslint-disable-next-line react-hooks/rules-of-hooks
                                  passHref
                                  legacyBehavior>
                                  <Stack direction={"row"} align={"center"}>
                                    <Box>
                                      <Text
                                        cursor={'pointer'}
                                        _hover={{ opacity: "0.8" }}
                                        transition={"all .3s ease"}
                                        _groupHover={{ color: "pink.400" }}
                                        fontWeight={500}
                                        lineHeight='24px'
                                        fontSize='16px'
                                        fontFamily={StaticUtils.titleFontName}
                                      >
                                        {child.name}
                                      </Text>
                                    </Box>
                                  </Stack>
                                </Link>
                              )
                            )}
                          </Stack>
                        </PopoverBody>
                      </PopoverContent>
                    )}
                  </Popover>
                </Box>
              );
              // }
            })}
            {/* more menu navigation bar */}
            {/* {mainCategories && mainCategories.length >= 4 && (
              <>
                <Box>
                  <Popover trigger={"hover"} placement={"bottom-start"}>
                    <PopoverTrigger>
                      <Flex
                        direction="column"
                        py="3"
                        alignItems="center"
                        justifyContent="center"
                        mt={{ lg: "20px", xl: "5px" }}
                      >
                        <Image
                          src="/more.jpg"
                          alt="appliances"
                          borderRadius="full"
                          border="1px"
                          ml="-3"
                          borderColor="gray.300"
                          width="48px"
                          height="48px"
                        />
                        <HStack spacing={0}>
                          <Text
                            fontSize={"sm"}
                            fontWeight={700}
                            color="gray.2"
                          >
                            More Categories
                          </Text>
                          <Box as={HiChevronDown} fontSize="md" />
                        </HStack>
                      </Flex>
                    </PopoverTrigger>
                    <PopoverContent
                      border={0}
                      boxShadow={"xl"}
                      bg={popoverContentBgColor}
                      p={4}
                      rounded={"xl"}
                      minW={"sm"}
                    >
                      <Stack>
                        {mainCategories.map((item: any, idx: number) => {
                          if (idx >= 4) {
                            return (
                              <Text
                                transition={"all .1s ease"}
                                fontWeight={500}
                                cursor={"pointer"}
                                onClick={() => handleNav(item.slug)}
                                display={"block"}
                                p={2}
                                rounded={"md"}
                              // eslint-disable-next-line react-hooks/rules-of-hooks

                              >
                                {item.name}
                              </Text>
                            );
                          }
                        })}
                      </Stack>
                    </PopoverContent>
                  </Popover>
                </Box>
              </>
            )} */}
          </Stack>
        </>
      )}
      {/* {isDesktop && (
        <>
          <Divider
            orientation="vertical"
            color="gray.800"
            opacity="1"
            borderLeftWidth="2px"
            ml="25px"
            mr="35px"
            h='85px'
          /> */}

          {/* <Stack
            direction={"row"}
            spacing={{ lg: "2", xl: "10", "2xl": "16" }}
            justifyContent="start"
            margin-left="38px"
          > */}
            {/* {featuredCollection && featuredCollection.map((navItem: any) => {
              return (

                <Box key={navItem.id}>
                  <Popover trigger={"hover"} placement={"bottom-start"}>
                    <PopoverTrigger>
                      <Flex
                        direction="column"
                        py="3"
                        alignItems="center"
                        justifyContent="center"
                        mt={{ lg: "20px", xl: "5px" }}
                      >
                        <Image
                          cursor={"pointer"}
                          onClick={() => handleNav(navItem.slug)}
                          src={navItem.featuredAsset?.preview}
                          alt="appliances"
                          borderRadius="full"
                          border="1px"
                          borderColor="gray.300"
                          width="48px"
                          height="48px"
                        />
                        <HStack spacing={0}>
                          <Text
                            fontSize="14px"
                            fontWeight={700}
                            color="gray.2"
                            _hover={{
                              textDecoration: "none",
                              color: linkHoverColor,
                            }}
                          >
                            {navItem.name}11
                          </Text>
                          {navItem.children.length > 0 && (
                            <Box as={HiChevronDown} fontSize="md" />
                          )}
                        </HStack>
                      </Flex>
                    </PopoverTrigger>
                    {navItem.children.length > 0 && (
                      <PopoverContent
                        border={0}
                        boxShadow={"xl"}
                        bg={popoverContentBgColor}
                        p={4}
                        rounded={"xl"}
                      >
                        <Stack>
                          {navItem.children.map(
                            (child: any, key: string) => (
                              <Link
                                key={key}
                                onClick={() => handleNav(child.slug)}
                                role={"group"}
                                display={"block"}
                                p={2}
                                rounded={"md"}
                              // eslint-disable-next-line react-hooks/rules-of-hooks

                              >
                                <Stack direction={"row"} align={"center"}>
                                  <Box>
                                    <Text
                                      transition={"all .3s ease"}
                                      _groupHover={{ color: "pink.400" }}
                                      fontWeight={500}
                                    >
                                      {child.name}
                                    </Text>
                                  </Box>
                                </Stack>
                              </Link>
                            )
                          )}
                        </Stack>
                      </PopoverContent>
                    )}
                  </Popover>
                </Box>
              );
            })} */}


            {/* <VStack style={{ 'margin': '0px' }} gap={{ base: '0px', lg: '20px' }} alignItems='flex-start'>
              <Text fontFamily={StaticUtils.titleFontName} fontSize='14px' fontWeight='500' opacity={'.6'} color='#1F2D47' lineHeight='20px'>Shop by brand</Text>
              <HStack gap={{ lg: '20px', xl: '20px', '2xl': '30px' }} style={{ 'margin': '0px' }}>
                {featuredCollection && featuredCollection.length && featuredCollection.map((navItem: any) => {
                  return (
                    <Box key={navItem.title}>
                      <Popover trigger={"hover"} placement={"bottom-start"}>
                        <PopoverTrigger>
                          <Link href={navItem?.url}>
                            <a>
                              <Flex
                                direction="column"
                                transition='all .3s ease 0s'
                                _hover={{ opacity: "0.8" }}
                              // mt={{ lg: "20px", xl: "5px" }}
                              >
                                <Image
                                  cursor={"pointer"}
                                  src={navItem?.logo}
                                  alt={navItem?.tooltip && navItem?.tooltip != "undefined" ? navItem.tooltip : navItem?.title}
                                  title={navItem?.tooltip && navItem?.tooltip != "undefined" ? navItem.tooltip : navItem?.title}
                                  maxWidth='100%'
                                  height="34px"
                                  minWidth={'auto'}
                                />
                              </Flex>
                            </a>
                          </Link>
                        </PopoverTrigger>
                      </Popover>
                    </Box>
                  );
                })}
              </HStack>
            </VStack> */}
          {/* </Stack> */}
        {/* </>
      )} */}
    </Center>
  );
};

export default CategoryList;

function handleNav(slug: any): void {
  Router.push("/category/" + slug.toLowerCase());
}

function handleFeaturedNav(slug: any): void {
  if (slug != "undefined")
    Router.push(slug);
}