import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Link,
  Image
} from "@chakra-ui/react";
import Router from "next/router";
import { useEffect, useState } from "react";

import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

export const Listing = (props: any) => {

  const [categories, setCategories] = useState<any>();


  useEffect(() => {
    setCategories(props.categories.filter((c: any) => {
      return !c.customFields.featuredCollection && c.featuredAsset
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function handleNav(slug: any): void {
    props.onClose();
    Router.push("/category/" + slug.toLowerCase());
  }

  const handleAllCategories = () => {
    props.onClose();

    Router.push("/search?search=;category=");
  };
  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem border="none" >
          {({ isExpanded }) => (
            <>
              <AccordionButton _focus={{ boxShadow: "none" }}>
                <Flex justifyContent="space-between" flex="1">
                  <Flex gap={4} ml="-18px">
                   
                    <Box
                      flex="1"
                      fontSize="16px"
                      fontWeight="500"
                      onClick={handleAllCategories}
                      my={1}
                    >
                      {"All Products"}
                    </Box>
                  </Flex>

                </Flex>
              </AccordionButton>

              <Divider color="#EAEAEA" />
            </>
          )}
        </AccordionItem>

        {
          categories?.map((data: any, id: any) => {
            return (
              <AccordionItem border="none" mb={1} key={id}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton _focus={{ boxShadow: "none" }} key={id}>
                      <Flex justifyContent="space-between" flex="1">
                        <Flex gap={4} ml="-18px"
                          onClick={() => handleNav(data.slug)}
                        >
                          <Box
                            flex="1"
                            fontSize="16px"
                            fontWeight="500"
                            my={1}
                          >
                            {data.name}
                          </Box>
                        </Flex>
                        {data.children.length > 0 &&

                          <Flex mr={-7} my={1}>

                            {isExpanded ? (
                              <MdOutlineKeyboardArrowDown
                                size={28}
                                color="#3F3F3F"
                              />) : (
                              <MdOutlineKeyboardArrowRight
                                size={28}
                                color="#3F3F3F"
                              />
                            )}
                          </Flex>
                        }
                      </Flex>
                    </AccordionButton>

                    {
                      data.children.length > 0 && (
                        <AccordionPanel>
                          {
                            data.children?.map((child: any, index: string) => {
                              return (
                                <Flex direction="row" gap={6} mb="10px" key={index}>
                                  <Link
                                    pb="8px"
                                    fontSize="16px"
                                    fontWeight="500"
                                    key={id}
                                    onClick={() => handleNav(child.slug)}
                                  >
                                    {child.name}
                                  </Link>
                                </Flex>
                              )
                            })
                          }
                        </AccordionPanel>
                      )
                    }

                    <Divider color="#EAEAEA" />
                  </>
                )}
              </AccordionItem>
            )
          })
        }
        {props?.headerLinksData && props?.headerLinksData?.length ?
          <Box mt='40px'>
            {props?.headerLinksData?.map((item: any, idx: any) => {
              return (
                !['undefined', undefined].includes(item?.url?.url) ?
                  < AccordionItem key={idx} border="none" >
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton _focus={{ boxShadow: "none" }}>
                          <Flex justifyContent="space-between" flex="1">
                            <Flex gap={4} ml="-18px">
                              <Link key={idx} href={item?.url?.url} style={{ textDecoration: 'none' }} aria-label={`Go to ${item?.link_title} page`}>
                                <Box
                                  flex="1"
                                  fontSize="16px"
                                  fontWeight="500"
                                  my={1}
                                >
                                  {(item?.link_title)}
                                </Box>
                              </Link>
                            </Flex>

                          </Flex>
                        </AccordionButton>

                        <Divider color="#EAEAEA" />
                      </>
                    )}
                  </AccordionItem>
                  : ''
              )
            })
            }

          </Box>
          : ''
        }
      </Accordion>
    </>
  )
}







