import * as React from 'react'

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  IconButton,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { CloseIcon, WarningTwoIcon } from "@chakra-ui/icons"
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { useEffect, useState } from "react"

import { PrismicRichText } from "@prismicio/react"
import StaticUtils from "../../services/static-utils.service"
import { config } from "../../services/config.service"
import { homepageservice } from "../../services/graphql/homepage.service";
import { links } from './_data'
import { orderlistService } from "../../services/graphql/orderList.service"
import { prismicPageDataService } from "../../services/prismicPage.service"
import router from "next/router"

const MainFooter = (props: any) => {
  const [order, setOrder] = useState([] as any);
  const isDesktop = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
    xl: true,
  });

  const [loginToken, setLoginToken] = useState<any>();
  const [customerActive, setCustomerActive] = useState<any>();
  const [meData, setMe] = React.useState<any>(null);

  const [show, setShow] = useState(true);
  const [orderLen, setOrderLen] = useState(false);

  const [footerLinks, setFooterLinks] = useState<any>([]);
  const [footerCategories, setCategories] = useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [footerNote, setFooterNote] = useState("");
  const [footerDesc, setFooterDesc] = useState<any>();


  let sortedOrders: any[] = [];
  let myDate: any[] = [];
  let refinedFooterData: any[] = [];
  let footerLinksData: any[];
  let footerText: any;

  const envFooterType: any = config.prismicFooter?.toString();
  const envFooterUID: any = config.prismicFooterUid?.toString();

  async function getFooterLinks() {
    try {
      let footerData: any = await prismicPageDataService.getReqPrismicCustomTypeData(`${envFooterType}`, `${envFooterUID}`);

      const fData = footerData?.props?.pageRes.slices;

      const cTypeData = footerData?.props?.pageRes;

      let note: any = cTypeData?.footer_note[0]?.text;
      setFooterDesc(cTypeData?.description || null);

      setFooterNote(note);
      setFooterLinks(fData);
    } catch (e) {
      console.log("footerData prismic fetch error: ", e)
    }

  }

  useEffect(() => {
    async function getMe() {
      try {
        const me = await orderlistService.getOrders();
        const orderArr = me.map((obj: any) => {

          if (obj.state == "PaymentSettled") {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            myDate = [...myDate, obj.code];

            setOrder(myDate);
            return
          }
        })

        if (me.length == 0) {
          setCustomerActive(false);
          setMe(null);
        } else {
          setMe(me);
          setCustomerActive(true);
        }
      } catch (e) {

      }
    }

    getMe();

    // getAllCategories();
    //if (props.footerCategories) {
    setCategories(props.footerCategories);
    //}
    getFooterLinks();

    if (order.length > 0) {
      setOrderLen(true);

      return;
    }
  }, [loginToken]);

  return (footerCategories && footerCategories.length ?
    <><Box bg="#FBF8F5">
      <Box bg="#1F2D47" color="white">
        <Container maxWidth="100%" as="footer" px={{ base: '40px', md: '40px', lg: '0px' }} role="contentinfo">
          <Stack
            justify="space-between"
            align={{ base: "start", md: "center", lg: "start", xl: "start" }}
            spacing={{ base: "10px", md: "10px", lg: "10px" }}
            direction={{ base: 'column', lg: 'row' }}
            py={{ base: '2px', md: '60px' }}
            px={{ base: '40px', md: '80px' }}
            pl={{ base: "0", lg: "232px" }}
            pr={{ base: "0", lg: "232px" }}
            pb={{ base: "93px" }}
          >
            <SimpleGrid mt={{ base: "18px", lg: "0" }} columns={{ base: 2, md: 4, lg: 5 }} gap={{ base: "40px", lg: "8" }} width={{ base: 'full', lg: '100%' }}>
              <Stack flex="none" flexGrow={0} alignSelf="stretch">
                <Stack spacing="1" shouldWrapChildren>
                  <Text color="#98A2B3" fontFamily={"Inter"} fontStyle={"normal"} fontWeight={"600"} fontSize={"14px"} lineHeight={"20px"}>Categories</Text>
                  {footerCategories?.map((catVal: any, catKey: any) => {
                    const url = (catVal?.slug) ? "/category/" + catVal?.slug.toLowerCase() : "#"
                    return (<Text textAlign="start" color="#E4E7EC" fontFamily={"Inter"} fontStyle={"normal"} fontSize="16px" fontWeight="500" lineHeight={"24px"} key={catKey} as="a" variant="link-on-accent" href={url || '/'} pb={"18px"} pt={"18px"}>
                      {catVal?.name}
                    </Text>)
                  })}
                </Stack>
              </Stack>
              {footerLinks.map((group: any, idx: any) => (
                <Stack key={idx} flex="none" flexGrow={0} alignSelf="stretch">
                  <Stack spacing="1" shouldWrapChildren>
                    <Text color="#98A2B3" fontFamily={"Inter"} fontStyle={"normal"} fontWeight={"600"} fontSize={"14px"} lineHeight={"20px"}>{`${group?.primary?.title[0]?.text}`}</Text>
                    {group.items.map((val: any, index: any) => (
                      <Text textAlign="start" color="#E4E7EC" fontFamily={"Inter"} fontStyle={"normal"} fontSize="16px" fontWeight="500" lineHeight={"24px"} key={index} as="a" variant="link-on-accent" href={val?.link?.url || '/'} pb={"18px"} pt={"18px"}>
                        {val?.link_title}
                      </Text>
                    ))}
                  </Stack>

                </Stack>
              ))}
            </SimpleGrid>
          </Stack>
        </Container>

        {!isDesktop && customerActive && show && orderLen &&

          <Flex flexDir="row" width="100%" background="primary" position="fixed" bottom="0px" zIndex="100">
            <CloseIcon alignItems="right" position="fixed" right="12px" mt="12px" fontSize="10px" onClick={() => setShow(false)} />
            <Flex verticalAlign='top' mt="25px" ml="15px"><WarningTwoIcon color="white" /></Flex>
            <Flex flexDir="column" fontWeight="600" color="white" fontSize="16px">
              <Flex flexDir="column" marginLeft="14px" alignItems="left" justifyContent="center" mb="16px">
                <Text marginBottom="10px" mt="16px" fontSize="16px">KYC Verification Alert</Text>
                <Text marginBottom="16px" fontSize="14px">Your last order #{order.slice(-1)} has missing KYC documents for smoother renting process</Text>
                <Button colorScheme='primary' borderBottomEndRadius="6px" outline="solid" backgroundColor="white" color="primary" width="207px" h="47px" onClick={() => router.push('/order')}>Upload Documents</Button>
              </Flex>
            </Flex>
          </Flex>}
      </Box>
    </Box>
      <Box
        bg="#F1F5F9"
        pt={{ base: "19px", lg: "50px" }}
        pl={{ base: "15px", lg: "219px" }}
        pr={{ base: "15px", lg: "225px" }}
        pb="20px"
        fontFamily={StaticUtils.titleFontName}
        fontWeight={{ base: "700px", lg: "400px" }}
        fontSize={{ base: "14px", lg: "16px" }}
        lineHeight={{ base: "24px", lg: "30px" }}
        color="#828282"
      >
        {footerDesc && <PrismicRichText field={footerDesc}></PrismicRichText>}

      </Box>
      <Stack
        pt={{ base: "0px", lg: "133px" }}
        justify="space-between"
        align="center"
        pl={{ base: "15px", lg: "232px" }}
        pr={{ base: "15px", lg: "232px" }}
        bg="#F1F5F9"
      >
        <Divider orientation='horizontal' color="#475467" height="1px" />
        <Text
          fontSize="16px"
          lineHeight="24px"
          fontWeight="400px"
          color="#374151"
          opacity="0.5"
          pt="32px"
          pb="28px"
          style={{ marginTop: "0px" }}
          fontFamily="Inter"
          fontStyle="normal">
          {footerNote}
        </Text>
      </Stack>
    </> : <Box></Box>
  )
}
export default MainFooter