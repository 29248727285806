import { Box, useBreakpointValue } from "@chakra-ui/react"

import Link from "next/link"
import { config } from '../../services/config.service'
import Image from 'next/image';

const Logo = () => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    return (
        <Box display={'flex'} flexDirection='row' alignItems={'left'} justifyContent='flex-start' mr={{ base: "0", lg: "30" }} mt={{ base: "8px", lg: "8px" }} height={"100%"}>
            <Link href="/">

                <Box maxWidth={{ base: "70", lg: "129px" }} maxHeight={{ base: "31", lg: "47px" }} cursor={"pointer"}>
                    <Image src={config.logoURL} alt="Rentity" width={isDesktop ? "129" : "70"} height={isDesktop ? "47" : "31"} />
                </Box>

            </Link >
        </Box>
    );
}
export default Logo