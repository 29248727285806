import {
  Box,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import { Listing } from "./Listing";

export const FilterNavigation = (props: any) => {
  const { onClose, isOpen } = props;

  return (
    <>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <CloseButton
              onClick={onClose}
              _focus={{ boxShadow: "none" }}
              bg="#D9D9D9"
              borderRadius="full"
            />
            <Box mt={18}>
              <Listing
                headerLinksData={props?.headerLinksData}
                categories={props.categories}
                brands={props.brands}
                onClose={onClose}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
