import { createContext, useEffect, useReducer } from "react";
import { cartservice } from "../services/graphql/cart.service";
import { Customer, Order } from "../services/graphql/generated-types";

export type ShippingAddressType = {
    fullName: string;
    address: string;
    city: string;
    postalCode: string;
    country: string;
  };
  
  type StateType = {
    cart: any;
    userInfo: Customer | null;
    navBarPincode: any;
  };
  
  type ContextType = {
    state: StateType;
    dispatch: React.Dispatch<any>;
  };
  
  enum actionTypes {
    CART_ADD_ITEM = "CART_ADD_ITEM",
    CART_REMOVE_ITEM = "CART_REMOVE_ITEM",
    CART_ADJUST_ITEM = "CART_ADJUST_ITEM",
    USER_LOGIN = "USER_LOGIN",
    USER_LOGOUT = "USER_LOGOUT",
    SAVE_SHIPPING_ADDRESS = "SAVE_SHIPPING_ADDRESS",
    SAVE_PAYMENT_METHOD = "SAVE_PAYMENT_METHOD",
    CART_CLEAR = "CART_CLEAR",
    SAVE_NAVBAR_PINCODE = "SAVE_NAVBAR_PINCODE"
  }
  
  interface Actions {
    type: string;
    payload?: Order | Customer | ShippingAddressType | string;
  }
  
  const initialState: StateType = {
    cart: {},
    userInfo: null,
    navBarPincode: null
  };
  
  const StoreContext = createContext<ContextType>({
    state: initialState,
    dispatch: () => null,
  });
  
  const reducer = (state = initialState, action: Actions): StateType => {
    switch (action.type) {
        case actionTypes.CART_ADD_ITEM:
        return { ...state, cart: action.payload as Order };
        case actionTypes.CART_REMOVE_ITEM:
          return { ...state, cart: action.payload as Order };
        case actionTypes.CART_ADJUST_ITEM:
          return { ...state, cart: action.payload as Order };
      case actionTypes.CART_CLEAR:
        return { ...state, cart: {} };
      case actionTypes.USER_LOGIN:
        return { ...state, userInfo: action.payload as Customer };
      case actionTypes.USER_LOGOUT:
        return {
          ...state,
          userInfo: null,
          cart: {},
        };
      case actionTypes.SAVE_SHIPPING_ADDRESS:
        return {
          ...state,
          cart: {
            ...state.cart,
            shippingAddress: action.payload as ShippingAddressType,
          },
        };
      case actionTypes.SAVE_PAYMENT_METHOD:
        return {
          ...state,
          cart: { ...state.cart, paymentMethod: action.payload as string },
        };
      case actionTypes.SAVE_NAVBAR_PINCODE:
        return {
          ...state,
          navBarPincode: action.payload as string
        };
      default:
        return state;
    }
  };
  
  const StoreProvider = ({ children } :any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
  
    return (
      <StoreContext.Provider value={{ state, dispatch }}>
        {children}
      </StoreContext.Provider>
    );
  };
  
  export { StoreProvider, actionTypes, StoreContext };