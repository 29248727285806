import { gql } from "@apollo/client";
import { createApolloClient } from "../apollo/apollo-client";

const subscriptionsLength = gql`
  query subcriptionsLength {
    activeCustomer {
      subscriptions {
        totalItems
      }
    }
  }
`;

export const GET_RENTAL_PLAN_FOR_EXTENSION = gql`
  query getRentalPlanForExtension($productVariantId: ID!) {
    getRentalPlanForExtension(productVariantId: $productVariantId) {
      plans {
        tenure
        deposit
        price
        rent
        rentWithTax
      }
    }
  }
`;

// extend subscription mutation
export const Extend_Subscription_Mutation = gql`
  mutation extendMonthlySubscription($input: ExtendMonthlySubscriptionInput!) {
    extendMonthlySubscription(input: $input) {
      ... on SubscriptionEntry {
        id
      }
      ... on NoSubscriptionEntryError {
        errorCode
        message
      }
      ... on SubscriptionExtensionFailedError {
        errorCode
        message
      }
    }
  }
`;

export const SubscriptionsListService = {
  async getSubscriptions(startPos: any, limit: any) {
    const client = createApolloClient();
    const Subscriptions_List = gql`
                query subcriptions{
                    activeCustomer{
                    id
                    title
                    firstName
                    lastName
                    phoneNumber
                    emailAddress
                    subscriptions(
                        options:
                        {
                            skip:${startPos}
                            take:${limit}
                            sort:{createdAt:DESC, updatedAt:DESC}}  
                        ){
                        totalItems
                        items{
                        id
                        createdAt
                        updatedAt 
                        purchasedAt
                        state
                        startedAt
                        expiresOn
                        endedAt
                        tenureInMonths
                        extendedExpiresOn
                        receivables {
                            state
                            dueDate
                            amount
                            receivedOn
                            id
                          }
                        rootSubscription {
                            id
                        }
                        children {
                            id
                            createdAt
                            updatedAt 
                            purchasedAt
                            state
                            startedAt
                            expiresOn
                            tenureInMonths
                            receivables {
                                state
                                dueDate
                                amount
                                receivedOn
                                id
                              }
                        }
                        orderLine{
                            id
                            quantity
                            productVariant{
                                id
                                product{
                                    slug
                                    name
                                    featuredAsset{
                                        source
                                    }
                                }
                            }
                            customFields{
                                rentalTenureInMonths
                                paymentSchedule
                                tenureUnit
                                securityDeposit
                            }
                        }
                        orderItem{
                            id
                        }
                        pickups{
                            id
                        state
                        fulfillments{
                            id
                            state
                            scheduledOn
                            scheduledSlot
                            shippedAt
                            deliveredAt
                        }
                        }
                        deposit{
                            id
                            createdAt
                            updatedAt
                            amount
                            refunds{
                            id
                            originalValue
                            state
                            adjustment
                            total
                            method
                            productCondition
                            comment
                            }
                        }
                        order{
                            customer{
                                id
                                firstName
                                lastName
                                phoneNumber
                                emailAddress
                            }
                            lines{
                                customFields{
                                    rentalTenureInMonths
                                    paymentSchedule
                                    tenureUnit
                                    securityDeposit
                                }
                            }
                            shippingAddress{
                            fullName
                            company
                            streetLine1
                            streetLine2
                            city
                            province
                            postalCode
                            country
                            phoneNumber
                            }
                        }
                        purchase{
                            id
                            purchasedOn
                            state
                        }
                        renewedSubscription{
                            id
                            createdAt
                            updatedAt
                            purchasedAt
                            state
                            renewedAt
                            startedAt
                            expiresOn
                            
                        }
                        }
                    }
                    }
                }
                `;

    const resSubscriptionsList = await client.query({
      query: Subscriptions_List,
    });
    if (
      resSubscriptionsList &&
      resSubscriptionsList?.data &&
      resSubscriptionsList?.data?.activeCustomer?.subscriptions
    )
      return Promise.resolve(
        resSubscriptionsList?.data?.activeCustomer?.subscriptions
      );
    else return Promise.resolve([]);
  },

  async getSubscriptionsLength() {
    const client = createApolloClient();

    const resSubscriptionsLength = await client.query({
      query: subscriptionsLength,
    });
    if (
      resSubscriptionsLength &&
      resSubscriptionsLength?.data &&
      resSubscriptionsLength?.data?.activeCustomer?.subscriptions
    )
      return Promise.resolve(
        resSubscriptionsLength?.data?.activeCustomer?.subscriptions
      );
    else return Promise.resolve([]);
  },

  //Get Extended Rental Plan
  async getRentalPlanforExtension(productVariantId: any) {
    const client = createApolloClient({
      "product-variant-id": productVariantId,
    });
    const res = await client.query({
      query: GET_RENTAL_PLAN_FOR_EXTENSION,
      variables: {
        productVariantId,
      },
    });
    if (res && res?.data?.getRentalPlanForExtension)
      return Promise.resolve(res.data.getRentalPlanForExtension);
    else return Promise.resolve([]);
  },

  // extend subscription
  async extendSubscription(input: any) {
    const client = createApolloClient();
    const response5 = await client.mutate({
      mutation: Extend_Subscription_Mutation,
      variables: {
        input: {
          tenure: input.tenure,
          rentPerMonthWithTax: input.rentPerMonthWithTax,
          subscriptionId: parseInt(input.subscriptionId),
        },
      },
    });
    if (response5 && response5.data) return Promise.resolve(response5.data);
    else return Promise.resolve([]);
  },
};
