import { config } from "./config.service";

export default class StaticUtils {
  static formatAmount(amount: any) {
    if (isNaN(amount) || !amount) {
      return amount;
    }

    if (amount === 0) {
      return amount;
    }

    return (amount / 100).toFixed(2);
  }

  // static titleFontName = 'Centra No2'
  static titleFontName = "titleFontName";

  static addVariantParam(variantId: string): string {
    if (config.isGroupSearchProduct) {
      return "";
    }

    return "?v=" + variantId;
  }
}
