import { gql } from '@apollo/client';
import { createApolloClient } from '../apollo/apollo-client';
import { CART_FRAGMENT } from './fragments.graphql';


export const Return_PickupOn_Subscription = gql`
mutation requestReturnPickupOnSubscription($input: CreateReturnPickupInput,){
  requestReturnPickupOnSubscription(input:$input){
    __typename
    ... on Pickup{
      id
      state
        notes{
        note
      }
      
      isPackagingNeeded
      pickupAddress{
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        countryCode
        phoneNumber
      }
    
      productVariant{
        id
        name
        featuredAsset{
          name
          preview
        }
      }
      subscription{
        id
        state
        startedAt
        expiresOn
        updatedAt
        createdAt
        purchasedAt
        renewedAt
        renewedSubscription{
          id
          startedAt
          expiresOn
          
        }
      }
    }
    ... on PickupRequestError{
      errorCode
      message
    }
    __typename
  }
}
`;




export const Order_List = gql`
  query CustomerOrders{
    activeCustomer{
      id
      title
      firstName
      lastName
      phoneNumber
      emailAddress
      orders(options:{ 
        filter: { 
          state: { notEq: "Delivered" } 
        }
        sort:{orderPlacedAt:DESC, updatedAt:DESC}
      }
      ){
        items{
          id
          createdAt
          updatedAt
          orderPlacedAt
          state
          code
          totalQuantity
          lines{
            id
            quantity
            productVariant{
              name
              price
              assets {
                preview
              }
              featuredAsset{
                preview
              }
              product{
                slug
                featuredAsset{
                  preview
                }
              }
            }
            items {
              id
              activeSubscription{
                startedAt
                expiresOn
                state
                
              }
              subscriptions{
                items{
                  id
                  state
                  startedAt
                  expiresOn
                }
              }
              fulfillment {
                id
                state
                method
                trackingCode
              }
              cancelled
            }
            
            featuredAsset {
              preview
            }
            order {
              orderPlacedAt
              state
              code
            }
          }
          fulfillments {
            state
            method
            orderItems{
              activeSubscription{
                startedAt
                expiresOn
              }
            }
            trackingCode
          }
        }
        
      }
      subscriptions(options:{sort:{createdAt:DESC, updatedAt:DESC}}){
        items{
          orderLine{
            id
            productVariant{
              product{
                name
                featuredAsset{
                  source
                }
              }
            }
          }
          pickups{
            id
           state
           subscription{
             id
             state
             pickups{
                id
                state
                
              }
             
           }
           fulfillments{
             id
             state
           }
         }
          order{
            id
            code
            state
            orderPlacedAt
            lines{
              id
              quantity
              fulfillments{
                id
                state
              }
              productVariant{
                id
                name
                product{
                  featuredAsset{
                    source
                  }
                }
                featuredAsset{
                  id
                  name
                  preview
                }
              }
            }
            shippingAddress{
              fullName
              company
              streetLine1
              streetLine2
              city
              province
              postalCode
              country
              phoneNumber
              
            }
          }
          id
          createdAt
          updatedAt 
          purchasedAt
          state
          startedAt
          expiresOn
          
          renewedSubscription{
            id
            createdAt
            updatedAt
            purchasedAt
            state
            renewedAt
            startedAt
            expiresOn
            
          }
        }
      }
    }
  }
  `;

export const GET_ORDER_BY_CODE = gql`
    query GetOrderByCode($code: String!) {
        orderByCode(code: $code) {
          fulfillments{
            method
            trackingCode
           
          }
          history{
            items{
              createdAt
              updatedAt
              type
              data
            }
          }
            ...Cart
            updatedAt
            orderPlacedAt
            customer {
                id
                emailAddress
                firstName
                lastName
                user {
                    id
                    identifier
                    verified
                    __typename
                }
            __typename
            }
            lines{
              id
              quantity
              order{
                lines{
                  productVariant{
                    name
                    featuredAsset{
                      preview
                      source
                    }
                  }
                }
              }
              productVariant{
                name
                product{
                  featuredAsset{
                    source
                    preview
                  }
                }
                price
                assets {
                  preview
                }
                featuredAsset{
                  preview
                }
              }
              items {
                id
                activeSubscription{
                  state
                  startedAt
                  expiresOn
                }
                discountedUnitPriceWithTax
                fulfillment {
                  id
                  state
                  method
                  trackingCode
                }
                cancelled
              }
              
              featuredAsset {
                preview
              }
              order {
                orderPlacedAt
                state
                code
                lines{
                  productVariant{
                    product{
                      name
                      featuredAsset{
                        preview
                        source
                      }
                    }
                  }
                
                }
              }
            }
            fulfillments {
              id
              state
              method
              trackingCode
            }
        __typename
        }
    }
    ${CART_FRAGMENT}
`;

export const
  orderlistService = {

    async getOrderCode(orderCode:any) {
      const client = createApolloClient();
     const Order_By_Code = gql`
     query CustomerOrderList{
      activeCustomer{
        id
        title
        firstName
        lastName
        phoneNumber
        emailAddress
        orders(options:{ filter: { 
          code: { eq: "${orderCode}" } 
        }sort:{orderPlacedAt:DESC, updatedAt:DESC}}){
          items{
            id
              state
              code
              shippingAddress{
                streetLine1
                streetLine2
                city
                province
                postalCode
                country
                phoneNumber
                countryCode
              }
            fulfillments{
          method
          trackingCode
         
        }
        history{
          items{
            createdAt
            updatedAt
            type
            data
          }
        }
          updatedAt
          orderPlacedAt
          customer {
              id
              emailAddress
              firstName
              lastName
              user {
                  id
                  identifier
                  verified
                  __typename
              }
          __typename
          }
          lines{
            id
            quantity
            featuredAsset{
              name
              preview
            }
            productVariant{
              name
              featuredAsset{
                preview
              }
            }
            order{
              lines{
                productVariant{
                  name
                  featuredAsset{
                    preview
                    source
                  }
                }
              }
            }
            productVariant{
              name
              product{
                name
                featuredAsset{
                  source
                  preview
                }
              }
              price
              assets {
                preview
              }
              featuredAsset{
                preview
              }
            }
            items {
              id
              activeSubscription{
                state
                startedAt
                expiresOn
              }
              fulfillment {
                id
                state
                method
                trackingCode
              }
              cancelled
            }
            
            featuredAsset {
              preview
            }
            order {
              orderPlacedAt
              state
              code
              lines{
                productVariant{
                  product{
                    name
                    featuredAsset{
                      preview
                      source
                    }
                  }
                }
              
              }
            }
          }
          fulfillments {
            id
            state
            method
            trackingCode
          }

        }
      }
      }
}
      `;
      const resSubsList = await client.query({
        query: Order_By_Code,
  
      });
      if (resSubsList && resSubsList.data && resSubsList.data.activeCustomer?.orders?.items)
        return Promise.resolve(resSubsList.data?.activeCustomer?.orders?.items);
      else return Promise.resolve([]);
    },



    async getOrders() {
      const client = createApolloClient();
      // caching logic
      const resOrderList = await client.query({
        query: Order_List,
      });
      if (resOrderList && resOrderList.data && resOrderList.data.activeCustomer)
        return Promise.resolve(resOrderList.data?.activeCustomer);
      else return Promise.resolve([]);
    },

   

    async getOrderByCode(code: any) {
      const client = createApolloClient();
      const resList = await client.query({
        query: GET_ORDER_BY_CODE,
        variables: {
          "code": code
        }
      });
      if (resList && resList.data.orderByCode)
        return Promise.resolve(resList.data?.orderByCode);
      else return Promise.resolve([]);
    },


    async setReturnPickupSubscription(input:any) {
      const client = createApolloClient();
      const response = await client.mutate({
        mutation: Return_PickupOn_Subscription,
        variables: {
          "input": {
            "subscriptionId":input.subscriptionId,
            "note":input.note, 
            "packagingNeeded":input.packagingNeeded, 
            "pickupAddress":{
              "streetLine1": input.streetLine1,
              "streetLine2": input.streetLine2,
              "city":input.city,
              "province":input.province,
              "postalCode":input.postalCode,
              "countryCode":input.countryCode,
              "phoneNumber":input.phoneNumber
            }
          }
          
        
        }
      });
      if (response && response.data)
      return Promise.resolve(response.data);
    else return Promise.resolve([]);
    },
  }