import { ERROR_RESULT_FRAGMENT } from "./fragments.graphql";
import { createApolloClient } from "../apollo/apollo-client";
import { gql } from "@apollo/client";

export const LOG_OUT = gql`
mutation SignOut {
    logout {
      success
      __typename
    }
  }

`;

export const logoutService = {
  async logoutUser() {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: LOG_OUT,
      variables: {
      },
    });

    window.localStorage.clear();
    window.sessionStorage.clear();

    if (response && response.data) {
      return Promise.resolve(response.data);
    } else return Promise.resolve([]);
  },
};
