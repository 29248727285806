

import { useMemo } from "react";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from
    "@apollo/client";
import { config } from "../config.service";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import Router from 'next/router'

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ operation,graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        let isUnAuth = false; 
        const pattern = /^\/order\/([A-Z0-9]+)$/;
        let path = "";
        if (typeof (window) != 'undefined') {
            path = window.location.pathname;
        } 
        const match = path.match(pattern); 
        if (match && operation?.operationName==="GetOrderByCode") {
          const orderCode = match[1];
            console.log("URL matches the pattern /order/<orderId>",orderCode);
            Router.push('/account/signin?code=' + orderCode);
        }else{
            graphQLErrors.forEach(({ extensions, message }) => {
                if (extensions && ['UNAUTHENTICATED', 'FORBIDDEN'].includes(extensions.code as any)) {
                    isUnAuth = true;
                }
                console.error(`[Backend Error] ${message}`)
            });

            if (isUnAuth) {
                console.error(`Looks like user's auth token has expired!`);
                if (typeof (window) != 'undefined') {
                    window.localStorage.clear();
                }
               Router.push('/account/signin?error=' + btoa(JSON.stringify(graphQLErrors)));
            }
        }
    }

    if (networkError) {
        //console.warn(`[Network error]: ${networkError}`);
    }
});

let apolloClient: any;

export function createApolloClient(additionalHeader?: any) {
    let authToken: any = '';
    let guestToken: any = '';
    let channelToken: any = '';

    try {
        if (typeof (window) != 'undefined') {
            guestToken = window?.localStorage.getItem('guestToken');
            if (guestToken) {
                authToken = guestToken;
            } else {
                authToken = window?.localStorage.getItem(config.localStorageTokenName);
            }
        }
    } catch (e) {

    }

    const getAuthHeaders = () => {
        if (!authToken) return null;

        return {
            authorization: `Bearer ${authToken}`,
        }
    }

    //After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.
    const afterwareLink = new ApolloLink((operation, forward) => {
        return forward(operation).map(response => {
            const context = operation.getContext()
            const {
                response: { headers }
            } = context

            if (headers) {
                const authToken = headers.get('Vendure-Auth-Token');

                if (authToken) {
                    if (typeof (window) != 'undefined') {
                        window.localStorage.setItem(config.localStorageTokenName, authToken)
                    }
                }
            }

            return response
        })
    })

    let headers = getAuthHeaders();

    if (additionalHeader) {
        if (headers) {
            headers = { ...headers, ...additionalHeader };
        } else {
            headers = additionalHeader;
        }
    }
    if (channelToken) {
        additionalHeader = { "vendure-token": channelToken }
        headers = { ...headers, ...additionalHeader };
    }
    const httplink:any = new HttpLink({
        uri: config.apiBaseURL,
        headers
    } as any);

    const uplodlink: any = new (createUploadLink as any)({
        uri: config.apiBaseURL,
        headers
    });

    const link = ApolloLink.split(
        operation => operation.getContext().hasUpload,
        uplodlink,
        httplink
    )

    return new ApolloClient({
        ssrMode: typeof window === "undefined", // set to true for SSR
        link: ApolloLink.from([errorLink, afterwareLink, link]),
        cache: new InMemoryCache(),

    });
}

export function initializeApollo(initialState: any = null) {
    const _apolloClient = apolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client,
    // the initial state gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Restore the cache using the data passed from
        // getStaticProps/getServerSideProps combined with the existing cached data
        _apolloClient.cache.restore({ ...existingCache, ...initialState });
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === "undefined") return _apolloClient;

    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;
    return _apolloClient;
}

export function useApollo(initialState: any) {
    const store = useMemo(() => initializeApollo(initialState), [initialState]);
    return store;
}