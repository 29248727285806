import * as prismic from "@prismicio/client";
import { config } from "../services/config.service";
import sm from "../sm.json";

const prismicAPI: any = config.prismicApiEndPoint?.toString();

export const prismicPageDataService = {
    async getReqPrismicPageData(pageId: any) {
        const client = prismic.createClient(prismicAPI);

        const page = await client.getByUID('page', pageId);

        const pageRes = page.data.slices[0].primary;

        return {
            props: { pageRes },
        }
    },

    async getReqPrismicTypeById(type: any, uId: any) {
        const client = prismic.createClient(prismicAPI);

        const page = await client.getByUID(type, uId);

        const pageRes = page.data.slices;

        return {
            props: { pageRes },
        }
    },

    async getReqPrismicCustomTypeData(type: any, uId: any) {
        const client = prismic.createClient(prismicAPI);

        const page = await client.getByUID(type, uId);

        const pageRes = page.data;

        return {
            props: { pageRes },
        }
    },

    async getAllFromCustomType(type: any) {
        const client = prismic.createClient(prismicAPI);

        const pages = await client.getAllByType(type, {});

        // const pageRes: Array<any> = pages[1].data.slices;
        const pageRes: Array<any> = [];
        pages.map((documents) =>
            pageRes.push(documents)
        );

        return {
            props: { pageRes },
        }
    }
    ,

    async getItemsFromCustomType(type: any, pageSize: any, page: any) {
        const client = prismic.createClient(prismicAPI);

        const options = {
            pageSize,
            page,
        };

        const pages = await client.query(
            prismic.predicate.at('document.type', type), options
        )
        
        const pageRes: Array<any> = [];
        pages.results.map((documents) =>
            pageRes.push(documents)
        );

        return {
            props: { pages, pageRes },
        }
    }
}