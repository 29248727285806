import { gql } from "@apollo/client";

export const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        width
        height
        name
        preview
        focalPoint {
            x
            y
        }
    }
`;

export const ORDER_ADDRESS_FRAGMENT = gql`
    fragment OrderAddress on OrderAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        phoneNumber
    }
`;

export const CART_FRAGMENT = gql`
    fragment Cart on Order {
        id
        code
        state
        active
        customer {
            id
            firstName
            lastName
            emailAddress
            phoneNumber
        }
        customFields {
            paymentSchedule
        }
        lines {
            items{
                activeSubscription{
                    state
                        startedAt
                        expiresOn
                }
          }
            id
            featuredAsset {
                ...Asset
            }
            unitPrice
            unitPriceWithTax
            quantity
            linePriceWithTax
            discountedLinePriceWithTax
            productVariant {
                product {
                    slug
                    name
                    facetValues{
                        facet{
                            code
                            name
                        }
                        name
                        customFields{
                            icon{
                                source
                            }
                        }                
                    }
                }
                id
                name
                sku
                rentalPlans
                rentalPricingPlans{
                    plans{
                        tenure
                        tenureUnit
                        deposit
                        securityDepositForShortTermWithEMandate
                        securityDepositForShortTermWithUpfront
                        securityDepositForTryAndBuyWithEMandate
                        securityDepositForTryAndBuyWithUpfront
                        price
                        rent
                        rentWithTax
                        isShortTermPlan
                    }
                }
                shortTermPricingPlan{
                    plans{
                        tenure
                        tenureUnit
                        deposit
                        securityDepositForShortTermWithEMandate
                        securityDepositForShortTermWithUpfront
                        securityDepositForTryAndBuyWithEMandate
                        securityDepositForTryAndBuyWithUpfront
                        price
                        rent
                        rentWithTax
                        isShortTermPlan         
                     }
                   }
                hasShortTermRentalPlan
                channels{
                    customFields{
                      deliverableZipCodes
                      deliverablePlaces
                    }
                }
            }
            discounts {
                amount
                amountWithTax
                description
                adjustmentSource
                type
            }
            customFields{
                purchaseType
                rentalTenureInMonths
                paymentSchedule
                tenureUnit
                rentPerMonthWithoutTax
                rentPerMonthWithTax
                securityDeposit
            }
        }
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        total
        totalWithTax
        shipping
        shippingWithTax
        shippingLines {
            priceWithTax
            shippingMethod {
                id
                code
                name
                description
            }
        }
        shippingAddress {
            ...OrderAddress
            __typename
        }
        billingAddress {
            ...OrderAddress
            __typename
        }
        payments {
            id
            method
            state
        }
        discounts {
            amount
            amountWithTax
            description
            adjustmentSource
            type
        }
    }
    ${ASSET_FRAGMENT}
    ${ORDER_ADDRESS_FRAGMENT}
`;

export const COUNTRY_FRAGMENT = gql`
    fragment Country on Country {
        id
        code
        name
        enabled
    }
`;

export const ADDRESS_FRAGMENT = gql`
    fragment Address on Address {
        id
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
            id
            code
            name
        }
        phoneNumber
        customFields{
            landmark
            zohoAddressId
        }
        defaultShippingAddress
        defaultBillingAddress
    }
`;

export const ERROR_RESULT_FRAGMENT = gql`
    fragment ErrorResult on ErrorResult {
        errorCode
        message
    }
`;
