import {
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { FilterNavigation } from "./FilterNavigation";

export const MobileNavigation = (props: any) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <IconButton
        ml="-35px"
        variant="ghost"
        icon={<FiMenu fontSize="1.25rem" />}
        aria-label="Open Menu"
        _focus={{ boxShadow: "none" }}
        onClick={onOpen}
      />
      <FilterNavigation
        isOpen={isOpen}
        headerLinksData={props?.headerLinksData}
        onClose={onClose}
        categories={props.categories}
        brands={props.brands}
      />
    </>
  );
};
