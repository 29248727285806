/* eslint-disable react/no-children-prop */
import { SearchIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  InputLeftElement,
  useBreakpointValue,
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Box,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { analytics } from "../../services/analytics.service";


const SearchBox = (props: any) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [catFilterValue, setCatFilterValue] = useState("All");
  const [brandfilterValue, setBrandFilterValue] = useState("All");
  const [click, setClick] = useState("");

  const router = useRouter();

  const handleCategoryFilter = (e: any) => {
    setCatFilterValue(e.target.innerText);
    setBrandFilterValue("");
  };
  const handleBrandFilter = (e: any) => {
    setBrandFilterValue(e.target.innerText);
    setCatFilterValue("");
  };

  const handleSearchFilter = async (event: any) => {
    if (event.key === "Enter") {
      let categoryId: any = "";
      let facetId: any;

      if (catFilterValue) {
        //category as a filter
        props.categories.map((data: any) => {
          if (data.name === catFilterValue) {
            categoryId = data.id;
          }
        });
        router.push(
          "/search?search=" + event.target.value + ";category=" + categoryId
        );
      } else {
        //brand as a filter
        props.brands.map((data: any) => {
          if (data.name === brandfilterValue) {
            facetId = data.id;
          }
        });
        router.push(
          "/search?search=" + event.target.value + ";category=;facet=" + facetId
        );
      }
    }
  };
  const handleSearchFilterClicked = async (event: any) => {
    setClick(event.target.value);   

    let categoryId: any = "";
    let facetId: any;

    if (catFilterValue) {
      //category as a filter
      props.categories.map((data: any) => {
        if (data.name === catFilterValue) {
          categoryId = data.id;
        }
      });
      router.push(
        "/search?search=" + click + ";category=" + categoryId
      );
    } else {
      //brand as a filter
      props.brands.map((data: any) => {
        if (data.name === brandfilterValue) {
          facetId = data.id;
        }
      });
      router.push(
        "/search?search=" + event.target.value + ";category=;facet=" + facetId
      );
    }

  };

  return (
    <InputGroup
      zIndex="1"
      mt={{ base: "40px", lg: "0" }}
      ml={{ base: "10px", lg: "60px" }}
      w={{ base: "95%", md: "97%", lg: "90%", xl: "520px" }}
      border="1px"
      bg={{ base: "headerInputbg", lg: "headerInputbg" }}
      borderColor={{ base: "gray.30", lg: "headerInputborder" }}
      borderRadius={{ base: "6px", lg: "4px" }}
    >
      {isDesktop && (
        <InputLeftAddon
          minW={{ lg: "10%", xl: "10%" }}
          maxW="38%"
          p="0"
          mt="1.8px"
          border="none"
          children={
            <Popover placement="bottom-start" >
              <PopoverTrigger >
                <Button variant={'nav-button'} color='#000000' fontWeight='500' fontSize='14px' display='inline-flex' justifyContent='space-between' zIndex={1} h="43px" w="100%">
                  {catFilterValue ? catFilterValue : brandfilterValue}
                  {/* <Box color='black' as={HiChevronDown} fontSize="xl" ml="8px" /> */}
                </Button>
              </PopoverTrigger>
              {/* <PopoverContent>
                <PopoverCloseButton />
                <PopoverHeader fontWeight="semibold" mt="10px" >
                  Categories
                </PopoverHeader>
                <PopoverBody>
                  <UnorderedList
                    fontSize="15px"
                    listStyleType="none"
                    spacing="2"
                    ml={0}

                  >

                    <ListItem
                      cursor="pointer"
                      onClick={(e: any) => handleCategoryFilter(e)}
                    >
                      {"All"}
                    </ListItem>

                    {props.categories?.map((category: any, key: any) => {
                      if (key <= 4) {
                        return (
                          <ListItem
                            cursor="pointer"
                            key={key}
                            onClick={(e: any) => handleCategoryFilter(e)}
                          >
                            {category.name}
                          </ListItem>
                        );
                      }
                    })}
                  </UnorderedList>
                  <Accordion allowToggle>
                    <AccordionItem border="none">
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          More
                        </Box>
                        <AccordionIcon mr={-3} />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <UnorderedList
                          fontSize="15px"
                          listStyleType="none"
                          spacing="3"
                        >
                          {props.categories?.map((category: any, key: any) => {
                            if (key > 4) {
                              return (
                                <ListItem
                                  cursor="pointer"
                                  key={key}
                                  onClick={(e: any) => handleCategoryFilter(e)}
                                >
                                  {category.name}
                                </ListItem>
                              );
                            }
                          })}
                        </UnorderedList>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </PopoverBody>
              </PopoverContent> */}
            </Popover>
          }
        />
      )}

      {isDesktop ? (
        <InputRightElement onClick={(e: any) => handleSearchFilterClicked(e)}>
          <SearchIcon boxSize="5" color="primary" mt="5px" cursor="pointer" />
        </InputRightElement>
      ) : (
        <InputLeftElement pointerEvents="none">
          <SearchIcon boxSize="5" mt="5px" ml="26" />
        </InputLeftElement>
      )}

      <Input
        onKeyPress={(e: any) => handleSearchFilter(e)}
        onChange={(event: any) => setClick(event.target.value)}
        placeholder="Search products"
        pl={{ base: "50px", lg: "10px" }}
        h="43px"
        border="none"
        w={{ base: "100%", lg: "100%", xl: "75%" }}
      />
    </InputGroup>
  );
};
export default SearchBox;
