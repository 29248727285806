import { gql } from "@apollo/client";
import { createApolloClient } from "../apollo/apollo-client";
import { ASSET_FRAGMENT } from './fragments.graphql';

import { config } from "../config.service";

export const GET_TRENDING_PRODUCTS = gql`
query GetTopSellers {
    search(input: {
        groupByProduct: true,
        sort: {
            price: ASC
        }
    }) {
        items {
            productId
            slug
            productAsset {
                id
                preview
            }
            priceWithTax {
                ... on PriceRange {
                    min
                    max
                }
            }
            minRentWithTax
            minRent
            productName
        }
    }
}
`;

export const GET_BRANDS = gql`
{
    facets(options: { filter :{ code:{ eq : "brand"}}}) {
      items {
        id
        createdAt
        updatedAt
        languageCode
        name
        code
        customFields
        values {
          name
          id
          code
        }
      }
      totalItems
    }
}
`;

export const GET_CATEGORIES = gql`
    query GetCollections($options: CollectionListOptions) {
  collections(options:$options) {
    items {
      id
      name
      slug
      assets{
        ...Asset
      }
      customFields{
        featuredCollection
        tagLine
        showInBanner
    }
    productVariants {
      totalItems
    }
      parent {
        id
        name
        slug
        __typename
      }
      position
      featuredAsset {
        ...Asset
        __typename
      }
      __typename
    }
    __typename
  }
}

${ASSET_FRAGMENT}
`;

export const homepageservice = {
  async getProductList() {
    const client = createApolloClient();

    const resCollectionList = await client.query({
      query: GET_TRENDING_PRODUCTS,
    });

    if (resCollectionList && resCollectionList.data)
      return Promise.resolve(resCollectionList.data.search.items);
    else return Promise.resolve([]);
  },

  async getBrands() {
    const client = createApolloClient();

    const resCollectionList = await client.query({
      query: GET_BRANDS,
    });

    if (resCollectionList && resCollectionList.data)
      return Promise.resolve(resCollectionList.data.facets.items[0].values);
    else return Promise.resolve([]);
  },

  async getCategories() {
    const client = createApolloClient();

    const resCollectionList = await client.query({
      query: GET_CATEGORIES,
      variables: {
        sort: {
          position: 'ASC'
        }
      }
    });

    if (resCollectionList && resCollectionList.data)
      return Promise.resolve(resCollectionList.data.collections.items);
    else return Promise.resolve([]);
  },
};