import { gql } from "@apollo/client";
import { createApolloClient } from "../apollo/apollo-client";
import { ERROR_RESULT_FRAGMENT, CART_FRAGMENT } from "./fragments.graphql";

export const FIREBASE_AUTHENTICATE = gql`
  mutation FirebaseAuthenticate(
    $input: AuthenticationInput!
    $rememberMe: Boolean
  ) {
    authenticate(input: $input, rememberMe: $rememberMe) {
      __typename
    }
  }
`;
export const SIGN_IN = gql`
  mutation SignIn(
    $emailAddress: String!
    $password: String!
    $rememberMe: Boolean!
  ) {
    login(
      username: $emailAddress
      password: $password
      rememberMe: $rememberMe
    ) {
      ... on CurrentUser {
        id
        __typename
      }
      ...ErrorResult
      __typename
    }
  }

  ${ERROR_RESULT_FRAGMENT}
`;

export const activeCustomer = gql`
  query ActiveCustomer {
    activeCustomer {
      id
      title
      firstName
      lastName
      phoneNumber
      emailAddress
      __typename
    }
  }
`;

export const GET_ACTIVE_ORDER = gql`
  query GetActiveOrder {
    activeOrder {
      ...Cart
    }
  }
  ${CART_FRAGMENT}
`;

export const FORGOT_PASSWORD = gql`
  mutation RequestPasswordReset($emailAddress: String!) {
    requestPasswordReset(emailAddress: $emailAddress) {
      ... on Success {
        success
        __typename
      }
      ...ErrorResult
      __typename
    }
  }
  ${ERROR_RESULT_FRAGMENT}
`;

export const IS_CUSTOMER_EXISTS_BY_PHONENUMBER = gql`
  query isCustomerExistsByPhoneNumber($phoneNumber: String!) {
    isCustomerExistsByPhoneNumber(phoneNumber: $phoneNumber)
  }
`;

export const IS_CUSTOMER_EXISTS_BY_EMAILADDRESS = gql`
  query isCustomerExistsByEmailAddress($emailAddress: String!) {
    isCustomerExistsByEmailAddress(emailAddress: $emailAddress)
  }
`;

export const signInService = {
  async signInUser(emailAddress: string, password: string) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: SIGN_IN,
      variables: {
        emailAddress: emailAddress,
        password: password,
        rememberMe: false,
      },
    });

    if (response && response.data) {
      return Promise.resolve(response.data);
    } else return Promise.resolve([]);
  },

  async firebaseAuth(
    token: string,
    phoneNumber: string,
    firstName?: string,
    lastName?: string,
    emailAddress?: string
  ) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: FIREBASE_AUTHENTICATE,
      variables: {
        input: {
          firebase: {
            token,
            phoneNumber,
            firstName,
            lastName,
            emailAddress,
          },
        },
        rememberMe: true,
      },
    });

    if (response) {
      return Promise.resolve(response);
    } else return Promise.resolve([]);
  },

  async isCustomerExistsByPhoneNumber(phoneNumber: string){
    const client = createApolloClient();
    const response = await client.query({
      query: IS_CUSTOMER_EXISTS_BY_PHONENUMBER,
      variables: {
       phoneNumber
      },
    });
    return Promise.resolve(response.data??[])
  },

  
  async isCustomerExistsByEmailAddress(emailAddress: string) {
    const client = createApolloClient();
    const response = await client.query({
      query: IS_CUSTOMER_EXISTS_BY_EMAILADDRESS,
      variables: {
      emailAddress
      },
    });
   return Promise.resolve(response.data??[])
  },

  async getActiveOrder() {
    const client = createApolloClient();
    // caching logic
    const res = await client.query({
      query: GET_ACTIVE_ORDER,
    });
    if (res && res.data && res.data.activeOrder)
      return Promise.resolve(res.data?.activeOrder);
    else return Promise.resolve([]);
  },

  async getActiveCustomer() {
    const client = createApolloClient();
    const response = await client.query({
      query: activeCustomer,
    });
    if (response && response.data.activeCustomer)
      return Promise.resolve(response.data.activeCustomer);
    else return Promise.resolve([]);
  },

  async forgotPassword(email: string) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: FORGOT_PASSWORD,
      variables: {
        emailAddress: email,
      },
    });
    if (response && response.data)
      return Promise.resolve(response.data.requestPasswordReset);
    else return Promise.resolve([]);
  },
};
