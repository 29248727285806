export const config = {
    logoURL: process.env.logo!,
    theme: process.env.theme || 'rentity',

    localStorageTokenName: process.env.localStorageTokenName || 'token',
    localStorageUserData: process.env.localStorageUserData || 'user',

    apiBaseURL: process.env.apiBaseURL,

    restApiBaseURL: process.env.restApiBaseURL,

    razorpayKey: process.env.razorpayKey,

    firebaseConfig: process.env.firebaseConfig,

    supportNumber:process.env.supportNumber,

    contractExpiryThreshold:process.env.contractExpiryThreshold,

    isUserWelcomeEnabled : process.env.isUserWelcomeEnabled,
    
    isMonthlyCollectionEnabled : process.env.isMonthlyCollectionEnabled, 

    isMonthlyCollectionForExtendSubEnable : process.env.isMonthlyCollectionForExtendSubEnable,

    // Repalcing Text form config file (Billed for tenure duration)
    isBilledTenureText : "",

    isBilledTenureTextinProductDetails : "",

    orderQuantityLimit:process.env.orderQuantityLimit,

    googleAnalyticsMeasurementId: process.env.googleAnalyticsMeasurementId,
    googleAnalyticsStreamId: process.env.googleAnalyticsStreamId,
    fuzzyMinMatchRate:process.env.fuzzyMinMatchRate,

    prismicTermsOfUse:process.env.prismicTermsOfUse,
    prismicPrivacyPolicy:process.env.prismicPrivacyPolicy,
    prismicContactPage:process.env.prismicContactPage,
    prismicContactCustomType:process.env.prismicContactCustomType,
    prismicAboutRentity:process.env.prismicAboutRentity,
    prismicCustomerReviews:process.env.prismicCustomerReviews,
    prismicHeroBanner:process.env.prismicHeroBanner,
    prismicWhyRentityPage:process.env.prismicWhyRentityPage,
    prismicWhyRentityCustomType:process.env.prismicWhyRentityCustomType,
    prismicHowItWorksCustomType:process.env.prismicHowItWorksCustomType,
    prismicFeaturedCollection:process.env.prismicFeaturedCollection,
    prismicFaqPage:process.env.prismicFaqPage,
    prismicFaqCustomType:process.env.prismicFaqCustomType,
    prismicHeroCarouselStaticBanner:process.env.prismicHeroCarouselStaticBanner,
    prismicHomepageCarouselType:process.env.prismicHomepageCarouselType,
    prismicHomepageBrowseByBrands:process.env.prismicHomepageBrowseByBrands,
    prismicFooter:process.env.prismicFooter,

    prismicCustomerReviewsUid:process.env.prismicCustomerReviewsUid,
    prismicHomepageHeroBannerUid:process.env.prismicHomepageHeroBannerUid,
    prismicHomepageStaticBannerUid:process.env.prismicHomepageStaticBannerUid,
    prismicHomepageBrandsBannerUid:process.env.prismicHomepageBrandsBannerUid,
    prismicHomepageCarouselTypeSectionUid:process.env.prismicHomepageCarouselTypeSectionUid,
    prismicContactUid:process.env.prismicContactUid,
    prismicFooterUid:process.env.prismicFooterUid,
    prismicFeaturedBrandsUid:process.env.prismicFeaturedBrandsUid,
    prismicHowItWorksUid:process.env.prismicHowItWorksUid,
    prismicRentityFeaturesUid:process.env.prismicRentityFeaturesUid,
    prismicContactUsPage:process.env.prismicContactUsPage,
    prismicContactUsDetailsUid:process.env.prismicContactUsDetailsUid,

    prismicHomepageCollectionSectionType:process.env.prismicHomepageCollectionSectionType,
    prismicHomepageCollectionSectionUid:process.env.prismicHomepageCollectionSectionUid,
    serverSideStaleTime:process.env.serverSideStaleTime, 
    
    prismicCollectionCarouselSliceType:process.env.prismicCollectionCarouselSliceType,

    prismicApiEndPoint:process.env.prismicApiEndPoint,

    prismicHeaderLinksType:process.env.prismicHeaderLinksType,
    prismicHeaderLinksUID:process.env.prismicHeaderLinksUID,
   
    showRentWithTax:process.env.showRentWithTax,

    priceStrikethroughPercentage:process.env.priceStrikethroughPercentage,

    
    clarityAnalyticsId:process.env.clarityAnalyticsId,
    webappURL:process.env.webappURL,

    isGroupSearchProduct: process.env.isGroupSearch,

    prismicBlogType:process.env.prismicBlogType,

    noOfRecordsPerPageDefault:process.env.noOfRecordsPerPageDefault,

    defaultPaymentSchedule: process.env.defaultPaymentSchedule || 'onetime',
}