/* eslint-disable import/no-anonymous-default-export */
var aquaBlue: any = '#E47066';
const defaultColor = '#F2F4F7';

export default {

    styles: {
        global: {
            
        }
    },

    initialColorMode: "dark",

    colors: {
        mainPadding: "38px",
        loginHeaderLink: "#FFFFFF",
        headerInputbg: "#F9F9F9",
        headerInputborder: "#7575754D",
        headerInputSelect: "#E9E9E9",
        cartBadge: "#FA3A3A",
        cartbg: "rgba(196, 196, 196, 0.1)",
        gray1: "rgba(0, 0, 0, 0.5)",
        bg: "#1C2B49",
        mainHeadBg: "#1C2B49",
        background: "black",
        transparent: "transparent",
        black: "#000",
        white: "#fff",
        red1: "#F50640",
        secondary: "#192439",
        loginLabel: "#E0E0E0",
        loginBtnLabel: '#E0E0E0',
        primary: aquaBlue,
        defaultColor: defaultColor,
        priceColor: '#F50640',
        header: "#05AC52",
        price: "#7B61FF",
        progress: "#DEE7F8",
        bg1: "#F0F5FF",
        bg3: " #F9FBFF",
        brown: "#CC520D",
        pastelred: "#BA51AB",
        navyblue: "#4A0DCC",
        brown1: "#7c7b7a",
        whiteChoclate: "#EAE5DA",
        infoBtn: {
            500: aquaBlue,
        },
        success: "#2E9D39",
        warning: "#FB822B",
        danger: "#FF0707",
        active: {
            100: '#1bc47d',
        },
        pending: {
            100: '#f24e1e',
        },
        draft: {
            100: '#38b0f5',
        },
        inactive: {
            100: '#ff0000',
        },

        gray: {
            1: '#333333',
            2: '#4F4F4F',
            3: "#828282",
            4: "#C4C4C464",
            5: "#E5E5E5",
            6: " #F0F5FF",
            10: "#C4C4C41A",
            20: "#EEEEEE",
            30: "#000000CC",
            50: "#f7fafc",
            100: "#EDF2F7",
            200: "#E2E8F0",
            300: "#CBD5E0",
            400: "#A0AEC0",
            500: "#718096",
            600: "#4A5568",
            700: "#2D3748",
            800: "#1A202C",
            900: "#171923",
        },

        // button scheme
        btnPrimary: {
            1: '#333333',
            2: '#4F4F4F',
            3: "#828282",
            4: "#E47066",
            50: "#f7fafc",
            100: aquaBlue,
            200: "#E2E8F0",
            300: "#CBD5E0",
            400: "#A0AEC0",
            500: aquaBlue,
            600: aquaBlue,
            700: "#2D3748",
            800: "white",
            900: "#171923",
        }
    },
    fonts: {
        heading: `Inter`,
        body: `Inter`,
    },
    layerStyles: {
        public: {
            backgroundImage: "/images/bgRentityLogin.svg",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            height: '100%',
            backgroundAttachment: 'fixed'
        }
    },


    components: {
        Button: {
            baseStyle: {
                fontSize: "218px",
                fontWeight: "700",
            },

            _hover: {
                backgroundColor: "orange",
                color: "aqua"
            },
            variants: {
                "solid": {
                    _hover: {
                        backgroundColor: '#D35C52',
                        color: '#FFF'
                    },

                    _active: {
                        backgroundColor: '#E47066',
                        color: '#FFF'
                    },

                    backgroundColor: '#E47066',
                    border: '1px solid #E47066',
                    borderRadius: '4px',
                    color: '#FFF',
                    borderColor: '#E47066',
                    height: '46px'
                },
                "outline": {
                    _hover: {
                        backgroundColor: '#E47066',
                        color: '#FFF',

                        _disabled: {
                            backgroundColor: 'initial',
                            color: '#E47066'
                        },
                    },

                    _active: {
                        backgroundColor: '#E47066',
                        color: '#FFF'
                    },

                    backgroundColor: 'transparent',
                    border: '1px solid #E47066',
                    borderRadius: '4px',
                    color: '#E47066',
                    borderColor: '#E47066',
                    height: '46px',
                    fontWeight:'700'
                },
                "outline2":{

                    disabled: {
                        backgroundColor: 'initial',
                        color: '#828282',
                    },
                    backgroundColor: 'transparent',
                    border: '1px solid #BDBDBD',
                    borderRadius: '4px',
                    color: '#828282',
                    borderColor: '#BDBDBD',
                    height: '46px',
                    fontWeight:'700'
                },

                "small-outline": {
                    _hover: {
                        backgroundColor: '#E47066',
                        color: '#FFF',
                        borderColor: '#E47066',

                        _disabled: {
                            backgroundColor: 'initial',
                            color: '#E47066'
                        },
                    },

                    _active: {
                        backgroundColor: '#E47066',
                        color: '#FFF',
                        borderColor: '#E47066',
                    },

                    backgroundColor: 'transparent',
                    border: '1px solid #000',
                    borderRadius: '2px',
                    color: '#000',
                    borderColor: '#000',
                    height: '29px'
                },

                "nav-button": {
                    _hover: {
                        backgroundColor: '#E47066',
                        color: '#FFF'
                    },

                    _active: {
                        backgroundColor: '#E47066',
                        color: '#FFF'
                    },

                    backgroundColor: 'transparent',
                    border: '1px solid',
                    borderRadius: '4px',
                    color: '#E47066',
                    borderColor: 'transparent',
                },

                "menu-ghost": {
                    // _hover: {
                    //     backgroundColor: 'transparent',
                    //     color: '#000'
                    // },

                    // _active: {
                    //     backgroundColor: '#E47066',
                    //     color: '#FFF'
                    // },

                    backgroundColor: 'transparent',
                    border: '1px solid',
                    borderRadius: '4px',
                    color: '#000',
                    borderColor: 'transparent',
                    height: 'auto'
                },
            },
        }
    }



}
