import "../styles/mobileGallery.css";
import "../styles/productdetails.css";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import '@fontsource/inter/400.css'
import '../styles/globals.css';

import { ChakraProvider, ColorModeScript, useBreakpointValue } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { useState } from "react";

import { ApolloProvider } from "@apollo/client";
import type { AppProps } from "next/app";
import DockerNavigation from "../components/Navigation/DockerNavigation";
import { Hydrate } from "react-query/hydration";
import MainFooter from "../components/Footer/MainFooter";
import Navbar from "../components/Header/Navbar";
import { ReactQueryDevtools } from "react-query/devtools";
import Script from "next/script";
import { StoreProvider } from "../context/user-context";
import Theme from "../styles/theme";
import { config } from "../services";
import { homepageservice } from "../services/graphql/homepage.service";
import { useApollo } from "../services/apollo/apollo-client";
import { useRouter } from "next/router";
import * as fbq from '../lib/fpixel';
function MyApp({ Component, pageProps }: AppProps) {

  console.log('page::::', Component?.name);

  const [queryClient] = useState(() => new QueryClient());
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();
  const { pathname } = router;
  const path = ["/checkout", "/payment"];
  const path1 = ["/categories", "/brands", "/account", "/checkout"];

  const [footerCategories, setCategories] = useState<any>([]);

  React.useEffect(() => {
    setCategories(pageProps.footerCategories);
  }, [pageProps]);

  React.useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <>
          <script async src={"https://www.googletagmanager.com/gtag/js?id=" + config.googleAnalyticsMeasurementId}></script>
          <Script id="ga-call" strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              function gtag_gtag(event, data){
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                  event: event,
                  ecommerce: data
                });
              }
              gtag('js', new Date());
              gtag('config', '${config.googleAnalyticsMeasurementId}', {
              page_path: window.location.pathname,
              });
          `}
          </Script>

          <Script id="clarity2" type="text/javascript" strategy="lazyOnload">
            {` (function(c,l,a,r,i,t,y){
          c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${config.clarityAnalyticsId}");
  `}
          </Script>
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
            }}
          />
          <ApolloProvider client={apolloClient}>
            <ChakraProvider resetCSS theme={Theme}>
              <ColorModeScript initialColorMode="light" />
              <StoreProvider>
                {path.includes(pathname) ? null : <Navbar {...pageProps} />}
                <Component {...pageProps} />
                {path1.includes(pathname) ? null : <MainFooter {...pageProps} />}
                {/* {path.includes(pathname) ? null : <DockerNavigation/>} */}
                {/* <DockerNavigation /> */}
              </StoreProvider>
            </ChakraProvider>
          </ApolloProvider>
        </>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export async function getServerSideProps({ req, res }: any) {
  let allCategories: any = await homepageservice.getCategories();

  let featuredCategories: Array<any> = [];
  let bannerCategories: Array<any> = [];
  let taglineCategories: Array<any> = [];
  let carouselCategories: Array<any> = [];

  let footerCategories: Array<any> = [];

  allCategories.forEach((cat: any) => {
    if (cat.customFields.featuredCollection) {
      featuredCategories.push(cat);
    }
    if (cat.customFields.showInBanner) {
      bannerCategories.push(cat);
    }

    if (cat.customFields.tagLine && !cat.customFields.featuredCollection) {
      taglineCategories.push(cat);
    }

    // if (cat.customFields.featuredCollection && cat.productVariants.totalItems) {
    if (cat.productVariants.totalItems && cat.customFields.featuredCollection) {
      carouselCategories.push(cat);
    }

    footerCategories = (allCategories.filter((c: any) => {
      return !c.customFields.featuredCollection && c.featuredAsset
    }));
  });

  return {
    props: {
      allCategories,
      featuredCategories,
      bannerCategories,
      taglineCategories,
      carouselCategories,
      footerCategories,
    },
  };
}

export default MyApp;
