import {
  Box,
  Button,
  Link,
  Container,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { StoreContext, actionTypes } from "../../context/user-context";
import router, { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

import Slider from "react-slick";

import CategoryList from "./CategoryList";
import DropDownAccount from "../Account/DropDownAccount";
import { FiUser } from "react-icons/fi";
import Logo from "./Logo";
import { MdOutlineLocationOn } from "react-icons/md";
import { MobileNavigation } from "../Navigation/MobileNavigation";
import PincodeOverlay from "../Card/PincodeOverlay";
import React from "react";
import SearchBox from "./SearchBox";
import StaticUtils from "../../services/static-utils.service";
import { cartservice } from "../../services/graphql/cart.service";
import { config } from "../../services/config.service";
import getSelectedAddressPin from "../Cart/checkDeliverablePin";
import { getURL } from "next/dist/shared/lib/utils";
import { homepageservice } from "../../services/graphql/homepage.service";
import { logoutService } from "../../services/graphql/logout.service";
import { orderlistService } from "../../services/graphql/orderList.service";
import { signInService } from "../../services/graphql/signIn.service";

import { prismicPageDataService } from "../../services/prismicPage.service";

const Navbar = (props: any) => {
  const isDesktop = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
    xl: true,
  });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [order, setOrder] = useState([] as any);
  const [isToken, setIsToken] = useState<any>();
  const [loginToken, setLoginToken] = useState<any>();
  const [customerActive, setCustomerActive] = useState<any>();
  const [brands, setBrands] = React.useState([]);
  const [categories, setCategories] = React.useState([] as any[]);
  const [cartItemCount, setCartItemCount] = React.useState(0);
  const [meData, setMe] = React.useState<any>(null);

  const { state, dispatch } = useContext(StoreContext);
  const [orderLen, setOrderLen] = useState(false);

  const router = useRouter();
  const queryParams = new URLSearchParams(router.asPath.split("?")[1]);
  const { pathname } = router;
  const path = ["/blog", "/blog/[blogId]"];
  const token = queryParams.get("authToken");

  const [me, setOrderData] = useState<any>();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1681,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1580,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  const [headerLinksData, setHeaderLinks] = React.useState([] as any);
  const envHeaderLinksType: any = config.prismicHeaderLinksType?.toString();
  const envHeaderLinksUID: any = config.prismicHeaderLinksUID?.toString();

  React.useEffect(() => {
    // get cart
    async function getCart() {
      const cart = await cartservice.getCartProducts();
      dispatch({ type: actionTypes.CART_ADD_ITEM, payload: cart });
    }

    getCart();

    //get all categories
    async function getAllCategories() {
      try {
        let allCat: any = [];
        if (props.allCategories && props.allCategories.length) {
          allCat = props.allCategories;
        } else {
          allCat = await homepageservice.getCategories();
        }

        let categories: {
          id: any;
          name: any;
          children: never[];
          slug: any;
          parent: any;
          featuredAsset: any;
        }[] = [];
        allCat.map((cat: any) => {
          let parentNode: any = {
            id: cat.id,
            name: cat.name,
            children: [] as never[],
            slug: cat.slug,
            parent: cat.parent,
            customFields: cat.customFields,
            featuredAsset: cat.featuredAsset,
          };
          allCat.map((cat2: any) => {
            if (cat.id === cat2.parent.id) {
              let child: any = {
                id: cat2.id,
                name: cat2.name,
                slug: cat2.slug,
              };
              parentNode.children.push(child);
            }
          });
          categories.push(parentNode);
        });

        setCategories(categories);
      } catch (e) {}
    }
    getAllCategories();
    setLoginToken(window?.localStorage.getItem(config.localStorageTokenName));

    const pin = window?.localStorage?.getItem("navbarPincode");
    dispatch({
      type: actionTypes.SAVE_NAVBAR_PINCODE,
      payload: pin,
    });

    async function getHeaderLinks() {
      try {
        const headerLinks =
          await prismicPageDataService.getReqPrismicCustomTypeData(
            `${envHeaderLinksType}`,
            `${envHeaderLinksUID}`
          );
        const linksData = headerLinks?.props?.pageRes?.slices[0]?.items;
        if (linksData?.length > 0) {
          setHeaderLinks(linksData);
        }
      } catch (e) {}
    }
    getHeaderLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allCategories]);

  const handleLogOut = async () => {
    const res = await logoutService.logoutUser();

    if (!res.logout.success) {
    } else {
      window.localStorage.removeItem(config.localStorageTokenName);
      setLoginToken(null);
      router.push("/");
      return;
    }
  };

  let sortedOrders: any[] = [];

  useEffect(() => {
    let myDate: any[] = [];
    async function getMe() {
      try {
        if (!me) {
          const me = await orderlistService.getOrders();
        }
        const me1 = await signInService.getActiveCustomer();

        if (!me1.id) {
          setCustomerActive(false);
          setMe(null);
        } else {
          setMe(me1);
          setCustomerActive(true);
          setOrderData(me);
        }
        const orderArr = me.order?.items?.map((obj: any) => {
          if (obj.state == "PaymentSettled") {
            myDate = [...myDate, obj.code];
            setOrder(myDate);
            return;
          }
        });
      } catch (e) {}
    }

    if (order.length > 0) {
      setOrderLen(true);
      return;
    }

    if (!meData) {
      getMe();
    } else {
      setCustomerActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken]);

  //get pincode
  async function getUrl() {
    //const pinCode = await getSelectedAddressPin();

    let cartUrl;
    // if(pinCode){
    //   cartUrl = "/cart?pincode="+pinCode;
    // }else{
    cartUrl = "/cart";
    // }

    return cartUrl;
  }

  const handlCartUrl = async () => {
    const cartUrl = getUrl();
    router.push(await cartUrl);
  };

  const handleorder = () => {
    router.push("/order");
  };

  const handleprofile = () => {
    router.push("/profile");
  };

  return (
    categories && (
      <Box
        as="section"
        display={{ base: "flex", sm: "flex", lg: "inline" }}
        bg={{ base: "#FBF8F5" }}
      >
        {isDesktop && customerActive && orderLen && (
          <Flex
            h="66px"
            flexDir="row"
            fontWeight="600"
            color="white"
            width="100%"
            background="primary"
            position="sticky"
            top="0px"
            zIndex="100"
            fontSize="16px"
          >
            <Flex alignItems="center" justifyContent="center">
              <WarningTwoIcon marginLeft="65px" color="white" />
            </Flex>
            <Flex
              flexDir="column"
              marginLeft="24px"
              alignItems="left"
              justifyContent="center"
            >
              <HStack>
                <Text>KYC Verification Alert</Text>
              </HStack>
              <HStack>
                <Text>
                  Your last order #{order.slice(-1)} has missing KYC documents
                  for smoother renting process
                </Text>
              </HStack>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              marginLeft="140px"
              fontWeight="700px"
            >
              <Button
                borderBottomEndRadius="6px"
                colorScheme="primary"
                outline="solid"
                backgroundColor="white"
                color="primary"
                onClick={() => router.push("/order")}
              >
                Upload Documents
              </Button>
            </Flex>
          </Flex>
        )}

        <Box w={{ base: "100%" }} as="nav">
          <Container
            maxWidth="100%"
            py={{ base: "0px" }}
            pt={{ lg: "1" }}
            mt={{ base: "0px" }}
            mb={{ base: "0px", lg: "40px" }}
            position="fixed"
            bg="#FBF8F5"
            zIndex="2"
            paddingInlineEnd={{ base: "0rem", lg: "1rem" }}
          >
            <Flex
              textAlign="center"
              justify="space-between"
              mx={{ base: "5", lg: "42px" }}
              py={{ base: "0", lg: "6px" }}
              bg="#FBF8F5"
            >
              <HStack
                bg="#FBF8F5"
                mt={{ base: 0, sm: "-5px" }}
                gap={{ base: "0px", lg: "20%" }}
                justifyItems="center"
                textAlign="center"
              >
                {!isDesktop && (
                  <MobileNavigation
                    headerLinksData={headerLinksData}
                    categories={categories}
                    brands={brands}
                  />
                )}
                <Stack
                  ml={{ base: "0", lg: "0", md: "0", xl: "0" }}
                  alignContent="center"
                >
                  <Logo />
                </Stack>
                {isDesktop && (
                  <SearchBox categories={categories} brands={brands} />
                )}
              </HStack>
              {isDesktop && (
                <>
                  <Flex gap="2">
                    {headerLinksData && headerLinksData?.length ? (
                      <HStack spacing="0" ml="20px">
                        {!["undefined", undefined].includes(
                          headerLinksData[0]?.url?.url
                        ) && (
                          <Link
                            href={headerLinksData[0]?.url?.url}
                            style={{
                              textDecoration: "none",
                              marginRight: "7px",
                            }}
                            aria-label={`Go to ${headerLinksData[0]?.link_title} page`}
                          >
                            <Button
                              fontSize="14px"
                              variant="ghost"
                              fontWeight="500"
                              bg="headerInputbg"
                              fontFamily={StaticUtils.titleFontName}
                              style={{ textDecoration: "none" }}
                            >
                              {(headerLinksData[0]?.link_title).toUpperCase()}
                            </Button>
                          </Link>
                        )}

                        {headerLinksData?.length > 1 ? (
                          <>
                            <Menu>
                              <MenuButton
                                as={Button}
                                fontFamily={StaticUtils.titleFontName}
                                variant="ghost"
                                rightIcon={<ChevronDownIcon boxSize={6} />}
                                fontWeight="500"
                                fontSize="14px"
                                _focus={{
                                  boxShadow: "none",
                                  backgroundColor: "none",
                                }}
                                backgroundColor="none"
                              >
                                MORE
                              </MenuButton>
                              <MenuList>
                                <MenuItem _focus={{ backgroundColor: "white" }}>
                                  {headerLinksData?.map(
                                    (item: any, idx: any) => {
                                      return !["undefined", undefined].includes(
                                        item?.url?.url
                                      ) && idx > 0 ? (
                                        <Link
                                          key={idx}
                                          href={item?.url?.url}
                                          style={{ textDecoration: "none" }}
                                          aria-label={`Go to ${item?.link_title} page`}
                                        >
                                          <Button
                                            fontSize="14px"
                                            variant="ghost"
                                            fontWeight="500"
                                            fontFamily={
                                              StaticUtils.titleFontName
                                            }
                                            style={{ textDecoration: "none" }}
                                          >
                                            {(item?.link_title).toUpperCase()}
                                          </Button>
                                        </Link>
                                      ) : (
                                        ""
                                      );
                                    }
                                  )}
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </>
                        ) : (
                          ""
                        )}
                      </HStack>
                    ) : (
                      ""
                    )}
                    <Menu>
                      <MenuButton
                        as={Button}
                        fontFamily={StaticUtils.titleFontName}
                        variant="ghost"
                        rightIcon={<ChevronDownIcon boxSize={6} />}
                        fontWeight="500"
                        fontSize="14px"
                        _focus={{ boxShadow: "none", backgroundColor: "none" }}
                        backgroundColor="none"
                      >
                        ACCOUNT
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          _hover={{ backgroundColor: "white" }}
                          _focus={{ backgroundColor: "white" }}
                        >
                          <DropDownAccount
                            me={meData}
                            customerActive={customerActive}
                            setCustomerActive={setCustomerActive}
                            setMe={setMe}
                          />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    <Button
                      leftIcon={
                        <Container
                          display={"flex"}
                          p="0"
                          alignItems="center"
                          justifyContent={"center"}
                          bgImage={"/local_mall.svg"}
                          boxSize="30px"
                          fontSize="13px"
                        >
                          <span style={{ paddingTop: "4px" }}>
                            {state?.cart?.totalQuantity || 0}
                          </span>
                        </Container>
                      }
                      fontSize="14px"
                      variant="ghost"
                      fontWeight="500"
                      bg="headerInputbg"
                      aria-hidden="true"
                      aria-label="cart"
                      onClick={handlCartUrl}
                      fontFamily={StaticUtils.titleFontName}
                    >
                      CART
                    </Button>

                    <Button
                      leftIcon={
                        <Icon boxSize="30px" as={MdOutlineLocationOn} />
                      }
                      variant="ghost"
                      bg="headerInputbg"
                      onClick={onOpen}
                    >
                      <Stack textAlign="left" spacing="0">
                        <Text
                          fontFamily={StaticUtils.titleFontName}
                          fontSize="14px"
                          fontWeight="500"
                        >
                          Deliver to
                        </Text>
                        <Text
                          fontFamily={StaticUtils.titleFontName}
                          fontSize="16px"
                          fontWeight="500"
                          color="#E47066"
                        >
                          {state.navBarPincode
                            ? state.navBarPincode
                            : "Set pincode"}
                        </Text>
                      </Stack>
                    </Button>
                  </Flex>
                </>
              )}
              {!isDesktop && (
                <HStack spacing="4" mr="-10px">
                  <Icon
                    as={MdOutlineLocationOn}
                    fontSize={20}
                    onClick={onOpen}
                  />
                  <Icon
                    as={FiUser}
                    fontSize={20}
                    onClick={() => router.push("/account")}
                  />
                  <Button
                    h="34px"
                    w="59px"
                    leftIcon={
                      <Container
                        display={"flex"}
                        p="0"
                        alignItems="center"
                        justifyContent={"center"}
                        bgImage={"/local_mall.svg"}
                        boxSize="30px"
                        fontSize="13px"
                      >
                        <span style={{ paddingTop: "4px" }}>
                          {state.cart.lines?.length}
                        </span>
                      </Container>
                    }
                    variant="ghost"
                    onClick={handlCartUrl}
                  ></Button>
                </HStack>
              )}
            </Flex>
          </Container>
          {isDesktop && <Divider />}
          {!isDesktop && <SearchBox categories={categories} brands={brands} />}

          {!path.includes(pathname) && (
            <Flex
              justifyContent="center"
              alignItems="center"
              boxShadow="0px 4px 8px rgba(0, 0, 0, 0.05)"
              mt={{ lg: "55px" }}
              mx={{ base: "5", lg: "0px" }}
              py={{ base: "0" }}
              mb={{ lg: "4px" }}
              paddingTop={{ lg: "23px" }}
              paddingBottom={{ lg: "19px" }}
            >
              {isDesktop &&
                (categories && categories.length ? (
                  <CategoryList categories={categories} brands={brands} />
                ) : (
                  <Box></Box>
                ))}
            </Flex>
          )}
        </Box>
        {!isDesktop && (
          <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                <VStack alignItems="flex-start" color="black" spacing={1}>
                  <Text fontSize="18px" fontWeight="500">
                    Enter delivery pincode
                  </Text>
                  <Text fontSize="14px" fontWeight="400">
                    We need to know delivery pincode to serve you better
                  </Text>
                </VStack>
              </DrawerHeader>
              <DrawerBody>
                <PincodeOverlay
                  navBarPincodeClicked={true}
                  closeOverlay={onClose}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
        {isDesktop && (
          <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay backdropFilter="blur(3px)" />
            <ModalContent>
              <ModalHeader>
                <VStack alignItems="flex-start" color="black" spacing={1}>
                  <Text fontSize="18px" fontWeight="500">
                    Enter delivery pincode
                  </Text>
                  <Text fontSize="14px" fontWeight="400">
                    We need to know delivery pincode to serve you better
                  </Text>
                </VStack>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <PincodeOverlay
                  navBarPincodeClicked={true}
                  closeOverlay={onClose}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </Box>
    )
  );
};

export default Navbar;
