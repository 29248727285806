import {
  ASSET_FRAGMENT,
  CART_FRAGMENT,
  ERROR_RESULT_FRAGMENT,
} from "./fragments.graphql";
import {
  RemoveAllOrderLinesDocument,
  RemoveAllOrderLinesMutationVariables,
  useRemoveAllOrderLinesMutation,
} from "./autogen/graphql";

import { createApolloClient } from "../apollo/apollo-client";
import { gql } from "@apollo/client";

export const ADD_TO_CART = gql`
  mutation AddItemToOrder(
    $productVariantId: ID!
    $quantity: Int!
    $customFields: OrderLineCustomFieldsInput
  ) {
    addItemToOrder(
      productVariantId: $productVariantId
      quantity: $quantity
      customFields: $customFields
    ) {
      ...Cart
    }
  }
  ${CART_FRAGMENT}
`;

export const ADJUST_CART_QUANTITY = gql`
  mutation AdjustItemQuantity(
    $id: ID!
    $qty: Int!
    $customFields: OrderLineCustomFieldsInput
  ) {
    adjustOrderLine(
      orderLineId: $id
      quantity: $qty
      customFields: $customFields
    ) {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  ${CART_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const ADJUST_ITEM_QUANTITY = gql`
  mutation AdjustItemQuantity(
    $id: ID!
    $qty: Int!
    $customFields: OrderLineCustomFieldsInput
  ) {
    adjustOrderLine(
      orderLineId: $id
      quantity: $qty
      customFields: $customFields
    ) {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  ${CART_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const GET_ACTIVE_ORDER = gql`
  {
    activeOrder {
      ...Cart
    }
  }
  ${CART_FRAGMENT}
`;

export const TRANSITION_TO_ADDINGITEMS = gql`
  mutation TransitionToAddingItems {
    transitionOrderToState(state: "AddingItems") {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  ${CART_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

const TRANSITION_TO_EMANDATEPENDING = gql`
  mutation transitionToNewStateForOrder($id: ID!) {
    transitionToNewStateForOrder(id: $id, state: "EmandatePending") {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  ${CART_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

const TRANSITION_TO_EMANDATEFAILED = gql`
  mutation transitionToNewStateForOrder($id: ID!) {
    transitionToNewStateForOrder(id: $id, state: "EmandateFailed") {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  ${CART_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

const TRANSITION_TO_PAYMENTCONFIRMED = gql`
  mutation transitionToNewStateForOrder($id: ID!) {
    transitionToNewStateForOrder(id: $id, state: "PaymentConfirmed") {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  ${CART_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;
export const REMOVE_CART_ITEM = gql`
  mutation RemoveItemFromCart($id: ID!) {
    removeOrderLine(orderLineId: $id) {
      ...Cart
      ...ErrorResult
      __typename
    }
  }
  ${CART_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

const UPDATE_ORDER_PAYMENT_SCHEDULE = gql`
  mutation updateOrderPaymentScheduleByOrderCode(
    $input: UpdatePaymentScheduleInput!
  ) {
    updateOrderPaymentScheduleByOrderCode(input: $input) {
      __typename
      ... on Order {
        id
      }
      ... on OrderModificationError {
        message
        errorCode
      }
    }
  }
`;

export var cartItems = new Array();

export const cartservice = {
  async addProductsToCart(id: any, quantity: number, customFields: any) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: ADD_TO_CART,
      variables: {
        productVariantId: Number(id),
        quantity: quantity,
        customFields: customFields,
      },
    });
    if (response && response.data) {
      return response.data.addItemToOrder;
    }
  },

  async getCartProducts() {
    const client = createApolloClient();
    // caching logic
    const resCartProductsList = await client.query({
      query: GET_ACTIVE_ORDER,
    });
    if (
      resCartProductsList &&
      resCartProductsList.data &&
      resCartProductsList.data.activeOrder
    )
      return Promise.resolve(resCartProductsList.data?.activeOrder);
    else return Promise.resolve([]);
  },

  async deleteProductsFromCart(id: string) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: REMOVE_CART_ITEM,
      variables: {
        id: id,
      },
    });
    if (response && response.data) {
      return response.data.removeOrderLine;
    }
  },

  async transitionToAddingItems() {
    const client = createApolloClient();
    const res = await client.mutate({
      mutation: TRANSITION_TO_ADDINGITEMS,
    });
    if (res && res.data) return Promise.resolve(res.data);
    else return Promise.resolve([]);
  },
  async transitionToEmandatePending(id: string) {
    const client = createApolloClient();
    const res = await client.mutate({
      mutation: TRANSITION_TO_EMANDATEPENDING,
      variables: {
        id: id,
      },
    });
    if (res && res.data) return Promise.resolve(res.data);
    else return Promise.resolve([]);
  },
  async transitionToEmandateFailed(id: string) {
    const client = createApolloClient();
    const res = await client.mutate({
      mutation: TRANSITION_TO_EMANDATEFAILED,
      variables: {
        id: id,
      },
    });
    if (res && res.data) return Promise.resolve(res.data);
    else return Promise.resolve([]);
  },
  async transitionToPaymentConfirmed(id: string) {
    const client = createApolloClient();
    const res = await client.mutate({
      mutation: TRANSITION_TO_PAYMENTCONFIRMED,
      variables: {
        id: id,
      },
    });
    if (res && res.data) return Promise.resolve(res.data);
    else return Promise.resolve([]);
  },
  async adjustCartItem(id: string, quantity: number, customFields: any) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: ADJUST_CART_QUANTITY,
      variables: {
        id: id,
        qty: quantity,
        customFields: customFields,
      },
    });
    if (response && response.data) {
      return response.data.adjustOrderLine;
    }
  },
  async adjustItemQuantity(id: string, quantity: number, customFields: any) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: ADJUST_ITEM_QUANTITY,
      variables: {
        id: id,
        qty: quantity,
        customFields: customFields,
      },
    });
    if (response && response.data) {
      return response.data.adjustOrderLine;
    }
  },

  async removeAllOrderLines(input: RemoveAllOrderLinesMutationVariables) {
    const client = createApolloClient();
    const response = await client.mutate({
      mutation: gql`
        ${RemoveAllOrderLinesDocument}
      `,
      variables: input,
    });

    if (response && response.data) {
      return response.data;
    }
  },

  async updateOrderPaymentSchedule(input: any) {
    const client = createApolloClient();
    const res = await client.mutate({
      mutation: UPDATE_ORDER_PAYMENT_SCHEDULE,
      variables: {
        input: {
          paymentSchedule: input.paymentSchedule,
          code: input.code,
        },
      },
    });
    if (res) {
      return Promise.resolve(res);
    }

    return Promise.resolve([]);
  },
};
