import React from "react";
import {
  PinInput,
  PinInputField,
  HStack,
  Text,
  Button,
  Link,
  useBreakpointValue,
  VStack,
  Spinner,
  Flex
} from "@chakra-ui/react";
import { actionTypes, StoreContext } from "../../context/user-context";

const PincodeOverlay = (props: any) => {
  const { state, dispatch } = React.useContext(StoreContext);
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const [pin, setPin] = React.useState(state.navBarPincode);
  const [pinMatch, setPinMatch] = React.useState("none");
  const [emptyPin, setEmptyPin] = React.useState("none");

  const handlePin = (value: any) => {
    setPinMatch("none");
    setEmptyPin("none");
    setPin(value);
  }

  const verify = async () => {
    let pinCheck;
    if (pin) {
      await props.pinList.forEach((element: any, index: any) => {
        if (element.trim() == pin.trim()) {
          pinCheck = true;
        }
      })
    } else {
      pinCheck = false;
    }
    if (pinCheck == true) {
      props.handleCart();
    } else {
      if (pin?.length == 0) {
        setEmptyPin("block");
      }
      else {
        setPinMatch("block");
      }
    }
  }

  const handleNavbarPincode = async () => {
    if (pin.length < 6) {
      setEmptyPin("block");
    } else {
      window.localStorage.setItem("navbarPincode", pin);
      dispatch({
        type: actionTypes.SAVE_NAVBAR_PINCODE,
        payload: pin
      });
      props.closeOverlay();
    }
  }

  return (
    <Flex minHeight={{ base: '32vh',sm:'0'}}>
      <VStack alignItems="flex-start" mb="4" spacing={4} >
        <HStack spacing={0} borderRadius="4px" border="1px solid" borderColor="#E0E0E0" bgColor="#F3F3F3" >
          <PinInput value={pin} onChange={handlePin} autoFocus={true}>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
        <Text display={pinMatch} color="#EB5757" fontSize="14px" fontWeight="500">We are unable to deliver to this location.</Text>
        <Text display={emptyPin} color="#EB5757" fontSize="14px" fontWeight="500">Please enter a valid pincode.</Text>
        <HStack fontWeight="700" fontSize="18px" spacing={4}>
          <Button disabled={props.loading} w="193px" h="46px" onClick={props.navBarPincodeClicked ? handleNavbarPincode : verify}>
            {props.navBarPincodeClicked ? 'Set pincode' : 'Verify'}
            {props.loading && <Spinner right={'5px'} position='absolute' />}
          </Button>
          <Link color="#E47066" onClick={props.closeOverlay}>cancel</Link>
        </HStack>
      </VStack>
    </Flex>
  )
}

export default PincodeOverlay;