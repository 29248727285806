import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { FiLogOut, FiUser } from "react-icons/fi";
import React, { useEffect, useMemo, useState } from "react";

import { IoBagAddOutline } from "react-icons/io5";
import Link from "next/link";
import { SubscriptionsListService } from "../../services/graphql/subscriptions.service";
import { TbTruckDelivery } from "react-icons/tb";
import { config } from "../../services/config.service";
import { logoutService } from "../../services/graphql/logout.service";
import { useRouter } from "next/router";

const DropDownAccount = (props: any) => {
  const isDesktop = useBreakpointValue({
    base: false,
    md: false,
    lg: false,
    xl: true,
  });
  const router = useRouter();
  const toast = useToast();
  const [loginToken, setLoginToken] = useState<any>();
  const [me, setMe] = useState<any>();
  const [customerActive, setCustomerActive] = useState<any>();
  const [subscriptionsLength, setSubscriptionsLength] = useState<any>();

  async function getSubscriptionsLength() {
    try {
      const subscription: any =
        await SubscriptionsListService.getSubscriptionsLength();
      setSubscriptionsLength(subscription.totalItems);
    } catch (e) {}
  }

  React.useEffect(() => {
    getSubscriptionsLength();
  }, []);

  const handleLogOut = async () => {
    const res = await logoutService.logoutUser();

    if (!res.logout.success) {
      return;
    } else {
      window.localStorage.removeItem(config.localStorageTokenName);
      setLoginToken(null);
      setMe(null);
      setCustomerActive(false);

      //newly added
      props.setCustomerActive(null);
      props.setMe(null);

      router.push("/");
      return;
    }
  };

  useMemo(() => {
    async function getMe() {
      try {
        if (props.customerActive === customerActive) {
          getSubscriptionsLength();
          return;
        }
        if (me && props.me && me.id === props.me.id) {
          return;
        }

        setCustomerActive(props.customerActive);
        setMe(props.me);
      } catch (e) {}
    }
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.me, props.customerActive]);

  return (
    <>
      <Grid templateRows="5" gap={0}>
        <GridItem w="100%" h="" my="3px">
          <HStack
            spacing={1}
            my="3px"
            gap="3"
            alignItems="center"
            justifyContent="center"
          >
            {customerActive && (
              <>
                <Box>
                  <Avatar
                    border="3px solid rgba(51, 115, 135, 1)"
                    height="70px"
                    width="70px"
                    name={me?.firstName + " " + me?.lastName}
                  />
                </Box>
                <VStack alignItems="start" spacing="1">
                  <Box fontWeight="700">
                    {me?.firstName} {me?.lastName}
                  </Box>
                  <Box fontWeight="500">{me?.emailAddress}</Box>
                  <Box fontWeight="500">{me?.phoneNumber}</Box>
                </VStack>
              </>
            )}
            {!customerActive && (
              <>
                <VStack>
                  <Link href={"/account/signin"}>
                    <Button
                      colorScheme="btnPrimary.4"
                      bg="primary"
                      _hover={{ bg: "#D35C52" }}
                      color="white"
                      height={"30px"}
                      w="151px"
                      // onClick={() => router.push("/account/signin")}
                    >
                      {" "}
                      Sign in{" "}
                    </Button>
                  </Link>
                  <HStack>
                    <Text>New to Rentity? </Text>
                    <Link
                      href={"/account/signup"}
                      // onClick={() => router.push("/account/signup")}
                    >
                      <Box color="btnPrimary.4">Sign up</Box>
                    </Link>
                  </HStack>
                </VStack>
              </>
            )}
          </HStack>
        </GridItem>
        <GridItem width="100%">
          <Accordion width="100%">
            <Link href={"/order"}>
              <AccordionItem>
                <AccordionButton py="10px" justifyContent="space-between">
                  <Box ml="24px">
                    <HStack>
                      <Box>
                        <IoBagAddOutline size="24px" />
                      </Box>
                      <Box textAlign="left">
                        <Flex direction="column">
                          <Box fontWeight="700" fontSize="16px">
                            Your Orders
                          </Box>
                          <Spacer />
                          <Box fontWeight="500" fontSize="12px">
                            Track order with order no or phone number
                          </Box>
                        </Flex>
                      </Box>
                    </HStack>
                  </Box>
                </AccordionButton>
              </AccordionItem>
            </Link>
          </Accordion>
        </GridItem>
        <GridItem width="100%">
          <Accordion width="100%">
            <Link href={"/account/rentalSubscriptions"}>
              <AccordionItem>
                <AccordionButton py="10px" justifyContent="space-between">
                  <Box ml="24px">
                    <HStack>
                      <Box>
                        <FiUser size="24px" />
                      </Box>
                      <Box textAlign="left">
                        <Flex direction="column">
                          <Box fontWeight="700" fontSize="16px">
                            Rental Subscriptions
                          </Box>
                          <Spacer />
                          <Box fontWeight="500" fontSize="12px">
                            {subscriptionsLength
                              ? `${subscriptionsLength} subscriptions`
                              : "View all your ongoing subscriptions"}
                          </Box>
                        </Flex>
                      </Box>
                    </HStack>
                  </Box>
                </AccordionButton>
              </AccordionItem>
            </Link>
          </Accordion>
        </GridItem>
        <GridItem width="100%">
          <Accordion width="100%">
            <Link href={customerActive ? "/account" : "/account/signin"}>
              <AccordionItem
              // onClick={() => customerActive ? router.push("/account") : router.push("/account/signin")}
              >
                <AccordionButton py="10px" justifyContent="space-between">
                  <Box ml="24px">
                    <HStack>
                      <Box>
                        <FiUser size="24px" />
                      </Box>
                      <Box textAlign="left">
                        <Flex direction="column">
                          <Box fontWeight="700" fontSize="16px">
                            Your Profile
                          </Box>
                          <Spacer />
                          <Box fontWeight="500" fontSize="12px">
                            Edit Your Profile
                          </Box>
                        </Flex>
                      </Box>
                    </HStack>
                  </Box>
                </AccordionButton>
              </AccordionItem>
            </Link>
          </Accordion>
        </GridItem>
        <GridItem width="100%">
          <Link
            href={
              customerActive ? "/account/shippingAddress" : "/account/signin"
            }
          >
            <Accordion
              width="100%"
              // onClick={() => customerActive ? router.push("/account/shippingAddress") : router.push("/account/signin")}
            >
              <AccordionItem>
                <>
                  <AccordionButton py="10px" justifyContent="space-between">
                    <Box ml="24px">
                      <HStack>
                        <Box>
                          <TbTruckDelivery size="24px" />
                        </Box>
                        <Box textAlign="left">
                          <Flex direction="column">
                            <Box fontWeight="700" fontSize="16px">
                              Addresses
                            </Box>
                            <Spacer />
                            <Box fontWeight="500" fontSize="12px">
                              Manage Your Addresses
                            </Box>
                          </Flex>
                        </Box>
                      </HStack>
                    </Box>
                  </AccordionButton>
                </>
              </AccordionItem>
            </Accordion>
          </Link>
        </GridItem>
        {customerActive && (
          <GridItem width="100%">
            <Accordion width="100%">
              <AccordionItem>
                <>
                  <AccordionButton
                    py="20px"
                    justifyContent="space-between"
                    onClick={handleLogOut}
                  >
                    <Box ml="24px">
                      <HStack>
                        <Box>
                          <FiLogOut size="24px" />
                        </Box>
                        <Box textAlign="left">
                          <Flex direction="column">
                            <Box fontWeight="700" fontSize="16px">
                              Logout
                            </Box>
                          </Flex>
                        </Box>
                      </HStack>
                    </Box>
                  </AccordionButton>
                </>
              </AccordionItem>
            </Accordion>
          </GridItem>
        )}
      </Grid>
    </>
  );
};
export default DropDownAccount;
